import { NetworkAccountsTypes } from '../types'
import { MODALS } from './modals/types'

export interface ICounterpartiesListType {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: ICounterpartiesCardProps[]
}

export interface ICounterpartiesState {
  counterpartiesList: ICounterpartiesListType
  counterpartiesSelectedList: ICounterpartiesListType
  hasCounterparties: boolean
  hasFilters: boolean
  modals: IModal | null
  filterValue?: string
}

export enum CounterpartiesActionTypes {
  SET_COUNTERPARTIES = 'ADS_MARKING_SET_COUNTERPARTIES',
  UPDATE_COUNTERPARTIES = 'ADS_MARKING_UPDATE_COUNTERPARTIES',
  SET_COUNTERPARTIES_LOADING = 'ADS_MARKING_SET_COUNTERPARTIES_LOADING',
  CLEAR_COUNTERPARTIES = 'ADS_MARKING_CLEAR_COUNTERPARTIES',
}

export enum CounterpartiesFormsActionTypes {
  SET_COUNTERPARTIES_SELECTED = 'ADS_MARKING_SET_COUNTERPARTIES_SELECTED',
  SET_COUNTERPARTIES_SELECTED_LOADING = 'ADS_MARKING_SET_COUNTERPARTIES_SELECTED_LOADING',
}

export enum CounterpartiesFilterActionTypes {
  SET_COUNTERPARTIES_FILTER = 'SET_COUNTERPARTIES_FILTER',
  CLEAR_COUNTERPARTIES_FILTER = 'CLEAR_COUNTERPARTIES_FILTER',
}

export interface ICounterpartiesCardProps {
  id: number
  is_you: boolean
  contractor_type: string
  contractor_origin: string
  contractor_legal_status: string
  phone_number?: string
  ru_name?: string
  tax_number?: string
  ru_kpp?: string
  ru_ceo?: string
  ru_legal_address?: string
  other_country?: string
  other_registration_number?: string
  other_payment_number?: string
  ad_platforms: NetworkAccountsTypes[]
  okved_codes: string[]
  ord_errors?: string[] | []
  is_client: boolean
  profile_id: number
  profileId?: number
}

export interface ICounterpartiesCardsProps {
  items: ICounterpartiesCardProps[]
}

export interface IModal {
  name: MODALS
  id?: number
  data?: ICounterpartiesCardProps
  updateListAction?: () => void
}

export enum COUNTERPARTY_ORIGIN {
  RUSSIA = 'russia',
  OTHER = 'other',
}
