import { Reducer } from 'redux'

import { AddYandexActionTypes } from './actions'
import { IAddYandexState } from './types'

export const initialState: IAddYandexState = {
  error: null,
  isLoading: false,
}

const addYandexReducer: Reducer<IAddYandexState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddYandexActionTypes.SET_ADD_YANDEX_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddYandexActionTypes.SET_ADD_YANDEX_ERROR: {
      return { ...state, error: action.data }
    }
    default: {
      return state
    }
  }
}

export default addYandexReducer
