import React from 'react'

import { SlateGreyLighten22, White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 14, height: 14 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2168_15526)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.1384 4.39445C6.56792 4.39445 6.10546 4.85692 6.10546 5.42739V5.56726C6.10546 5.84647 5.87911 6.07281 5.5999 6.07281C5.32069 6.07281 5.09434 5.84647 5.09434 5.56726V5.42739C5.09434 4.29849 6.0095 3.38334 7.1384 3.38334H7.17259C8.28261 3.38334 9.18245 4.28319 9.18245 5.39321C9.18245 5.98973 8.88353 6.54656 8.38634 6.87618L8.11707 7.0547C7.73425 7.30849 7.50409 7.73723 7.50409 8.19653V8.36453C7.50409 8.64374 7.27775 8.87009 6.99854 8.87009C6.71933 8.87009 6.49298 8.64374 6.49298 8.36453V8.19653C6.49298 7.39824 6.89302 6.65307 7.55837 6.21196L7.82764 6.03345C8.04229 5.89114 8.17134 5.65074 8.17134 5.39321C8.17134 4.84161 7.72419 4.39445 7.17259 4.39445H7.1384Z"
          fill={fill}
        />
        <path
          d="M7.51322 9.88332C7.51322 10.1625 7.28688 10.3889 7.00767 10.3889C6.72846 10.3889 6.50211 10.1625 6.50211 9.88332C6.50211 9.60411 6.72846 9.37776 7.00767 9.37776C7.28688 9.37776 7.51322 9.60411 7.51322 9.88332Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99989 1.47779C3.95005 1.47779 1.47766 3.95017 1.47766 7.00001C1.47766 10.0498 3.95005 12.5222 6.99989 12.5222C10.0497 12.5222 12.5221 10.0498 12.5221 7.00001C12.5221 3.95017 10.0497 1.47779 6.99989 1.47779ZM0.466553 7.00001C0.466553 3.39175 3.39163 0.466675 6.99989 0.466675C10.6081 0.466675 13.5332 3.39175 13.5332 7.00001C13.5332 10.6083 10.6081 13.5333 6.99989 13.5333C3.39163 13.5333 0.466553 10.6083 0.466553 7.00001Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2168_15526">
          <rect width="14" height="14" fill={White} />
        </clipPath>
      </defs>
    </svg>
  )
}
