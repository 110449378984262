import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import selector from './selector'

import ProductTour from '@/components/ProductTour'
import { Routes } from '@/const/routes'
import { getPopupAnnouncementSteps } from '@/pages/Referral/utils'
import { setUserPlugin } from '@/store/user'
import { PLUGINS } from '@/store/user/types'

const ReferralAnnouncement = () => {
  const { isProductTourShown, isDesktop } = useSelector(selector)

  const shouldProductTourRender = isDesktop && isProductTourShown

  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (shouldProductTourRender) {
      setIsOpen(true)
    }
  }, [shouldProductTourRender])

  const closeTourWithRedirect = (hasRedirect: boolean) => {
    return async () => {
      setIsOpen(false)

      if (isProductTourShown) {
        await dispatch(setUserPlugin(PLUGINS.NEW_REF_ANNOUNCEMENT))
        if (hasRedirect) {
          history.push(Routes.REFERRAL)
        }
      }
    }
  }

  return (
    <ProductTour
      steps={getPopupAnnouncementSteps(closeTourWithRedirect(true))}
      isOpen={isOpen}
      onRequestClose={closeTourWithRedirect(false)}
      showNavigation={false}
      showButtons={false}
    />
  )
}

export default ReferralAnnouncement
