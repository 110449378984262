import React from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import selector from './selector'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import { Button, BUTTON_FILL_TYPE, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { CopyLinkIcon } from '@/components/Icons'
import { IToast, Toast, TOAST_VARIANT } from '@/components/ToastSMB'
import { PRODUCTION_URL } from '@/const'
import { Routes, SearchParams } from '@/const/routes'
import {
  MIXPANEL_EVENTS_REFERRAL,
  MIXPANEL_PARAMS_KEYS_REFERRAL,
  MIXPANEL_PARAMS_VALUES_REFERRAL,
} from '@/pages/Referral/consts'

const ReferralButton = () => {
  const { isMenuOpened, isDesktop, code } = useSelector(selector)
  const { formatMessage } = useIntl()
  const classes = useStyles()

  const shouldRenderIconButton = isDesktop && !isMenuOpened
  const hasCode = !!code

  const copyToast = (t: IToast) => (
    <Toast
      content={formatMessage({ id: 'common.copied' })}
      onClose={() => toast.dismiss(t.id)}
      variant={TOAST_VARIANT.success}
    />
  )

  const copy = () => {
    const link = `${PRODUCTION_URL}${Routes.SIGNUP}/?${SearchParams.REF}=${code}`

    navigator.clipboard.writeText(link).then(() => {
      toast.custom(copyToast)
      Mixpanel.track(MIXPANEL_EVENTS_REFERRAL.REFERRAL_PROGRAM_ITEM_COPY, {
        [MIXPANEL_PARAMS_KEYS_REFERRAL.ITEM]: MIXPANEL_PARAMS_VALUES_REFERRAL.LINK,
        [MIXPANEL_PARAMS_KEYS_REFERRAL.PLACE]: MIXPANEL_PARAMS_VALUES_REFERRAL.MENU,
      })
    })
  }

  if (!hasCode) {
    return null
  }

  return shouldRenderIconButton ? (
    <Button
      variant={BUTTON_VARIANT.green}
      size={BUTTON_SIZE.icon36}
      iconLeft={<CopyLinkIcon />}
      fillIconType={BUTTON_FILL_TYPE.stroke}
      onClick={copy}
      className={classes.mobileButton}
    />
  ) : (
    <Button
      variant={BUTTON_VARIANT.green}
      size={BUTTON_SIZE.size35}
      iconLeft={<CopyLinkIcon />}
      label={formatMessage({ id: 'menu.referralLink' })}
      fullWidth
      fillIconType={BUTTON_FILL_TYPE.stroke}
      onClick={copy}
      className={classes.button}
    />
  )
}

export default ReferralButton
