export const enum MIXPANEL_HEADER_KEYS {
  EDUCATION_MATERIALS_CLICK = 'EducationMaterialsClick',
  GO_TO_HELP = 'GoToHelp',
}

export const enum MIXPANEL_HEADER_PARAMS {
  PLACE = 'place',
}

export const enum MIXPANEL_HEADER_VALUE {
  HEADER = 'header',
}
