import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { IAdAccount, IAdAccountsState } from '../adaccounts/types'
import { ThunkResult } from '../store'
import { IUpdateProfileData } from '../user/types'
import { ClientPageType, ClientsActionTypes, IClientProfile, IClientRequest, IClientsObject } from './types'

import { IComboBoxOption } from '@/components/ComboBox'
import { API_URL } from '@/const'
import { IClientBody } from '@/pages/AddClient/types'
import { customAxios } from '@/utils'

export const getClients = (page: ClientPageType, term?: string): ThunkResult<Promise<any>> => {
  return async (dispatch: Dispatch<AnyAction>) => {
    let url = `${API_URL}/profile_agency/clients/`

    if (page) {
      url = `${url}?page=${page}`
    }

    if (term) {
      url = `${url}&q=${encodeURIComponent(term)}`
    }

    try {
      dispatch({
        type: ClientsActionTypes.SET_CLIENT_IS_LOADING,
        data: true,
      })

      const response = await customAxios(url, 'GET', null, true)
      dispatch({
        data: response.data,
        page,
        type: ClientsActionTypes.SET_CLIENTS,
      })
      return response
    } catch (error) {
      Promise.reject(error)
    } finally {
      dispatch({
        type: ClientsActionTypes.SET_CLIENT_IS_LOADING,
        data: false,
      })
    }
  }
}

export function getClientAccounts(clientId: number): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/clients/${clientId}/`, 'GET', null, true)
      .then((response: AxiosResponse<{ accounts: IAdAccount[]; profile: IClientProfile }>) => {
        dispatch({
          data: { accounts: response.data.accounts, profile: response.data.profile, clientId },
          type: ClientsActionTypes.SET_CLIENT_ACCOUNTS,
        })

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function addClient(body: IClientBody): AxiosPromise<IClientBody> {
  return customAxios(`${API_URL}/profile_agency/add_client/`, 'POST', body, true)
    .then((response: AxiosResponse<IAdAccountsState>): any => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getClientRequests(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const url = `${API_URL}/profile_agency/clients_requests/`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IClientRequest[]>) => {
        dispatch({
          data: response.data,
          type: ClientsActionTypes.SET_CLIENT_REQUESTS,
        })

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function resendEmail(clientId: number): AxiosPromise {
  return customAxios(`${API_URL}/profile_agency/client/${clientId}/send_email/`, 'GET', null, true)
    .then((response: AxiosResponse) => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function updateClientProfile(clientId: number, body: IUpdateProfileData): AxiosPromise {
  return customAxios(`${API_URL}/profile_agency/clients/${clientId}/`, 'PATCH', body, true)
    .then((response: AxiosResponse) => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export const getSearchClients = (inputValue: string, callback: (options: IComboBoxOption[]) => void) => {
  const url = `${API_URL}/profile_agency/clients/?q=${inputValue}`

  customAxios(url, 'GET', null, true).then((response: AxiosResponse<IClientsObject>) => {
    const normalized = response.data.results.map(({ inn, name }) => ({
      value: inn,
      label: name,
      subLabel: inn,
    }))
    callback(normalized)
  })
}
