import { createSelector } from 'reselect'

import { getAdsMarkingAccount, getBrowser, getProfile } from '@/common/selectors'
import IApplicationState from '@/types/state'

export const adsMarkingSelector = createSelector(
  [getProfile, getAdsMarkingAccount, getBrowser],
  (profile, accounts, browser) => ({
    isPayingCustomer: profile.paying_customer,
    shouldStartProductTour:
      !profile.ads_marking_product_tour && !!accounts.accountsList.results && browser.greaterThan.medium,
    profileAdmarkInfo: accounts.profileAdmarkInfo,
    accountsFilled: accounts.accountsFilled,
    shouldStartClientAccountsProductTour:
      profile.ads_marking_product_tour &&
      !profile.ads_marking_client_accounts_product_tour &&
      profile.is_profile_agency,
  })
)

export const shouldRenderAdsMarking = (state: IApplicationState) =>
  state.user.profile.is_profile_client && !state.user.profile.is_pay_own ? false : true
