import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactourStep } from 'reactour'

import useStyles from './styles'

import { Button, BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { StarIcon } from '@/components/Icons'
import { PurpleLighten25 } from '@/const/colors'

export const getGeneralAnnouncementSteps = (onClose: () => void): ReactourStep[] => {
  const classes = useStyles()

  return [
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="referral.productTour.announcement.title" />
          </h4>
          <div className={classes.description}>
            <FormattedMessage id="referral.productTour.announcement.description" />
          </div>
          <div className={classes.list}>
            {Array.from({ length: 3 }, (v, k) => k + 1).map((index) => (
              <div className={classes.listItem} key={index}>
                <StarIcon style={{ width: 14, height: 14 }} fill={PurpleLighten25} />
                <span>
                  <FormattedMessage id={`referral.productTour.announcement.listItem_${index}`} />
                </span>
              </div>
            ))}
          </div>
          <div className={classes.text}>
            <FormattedMessage id="referral.productTour.announcement.text" />
          </div>
          <Button size={BUTTON_SIZE.size40} variant={BUTTON_VARIANT.green} type={BUTTON_TYPE.button} onClick={onClose}>
            <FormattedMessage id="referral.productTour.announcement.button" />
          </Button>
        </div>
      ),
      position: 'center',
    },
  ]
}

export const getWithdrawAnnouncementSteps = (onClose: () => void): ReactourStep[] => {
  const classes = useStyles()

  return [
    {
      content: (
        <div>
          <div className={classes.withdrawTitle}>
            <FormattedMessage id="referral.productTour.withdrawAnnouncement.title" />
          </div>
          <div className={classes.withdrawDescription}>
            <FormattedMessage id="referral.productTour.withdrawAnnouncement.description" />
          </div>
          <Button size={BUTTON_SIZE.size40} variant={BUTTON_VARIANT.green} type={BUTTON_TYPE.button} onClick={onClose}>
            <FormattedMessage id="referral.productTour.announcement.button" />
          </Button>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="referral-withdraw"]',
      style: { top: '14px' },
    },
  ]
}

export const getPopupAnnouncementSteps = (onClose: () => void): ReactourStep[] => {
  const classes = useStyles()

  return [
    {
      content: (
        <div>
          <div className={classes.withdrawTitle}>
            <FormattedMessage id="referral.productTour.popupAnnouncement.title" />
          </div>
          <div className={classes.withdrawDescription}>
            <FormattedMessage id="referral.productTour.popupAnnouncement.description" />
          </div>
          <Button size={BUTTON_SIZE.size40} variant={BUTTON_VARIANT.green} type={BUTTON_TYPE.button} onClick={onClose}>
            <FormattedMessage id="referral.productTour.popupAnnouncement.button" />
          </Button>
        </div>
      ),
      position: 'left',
      selector: '[data-tour-id="referral-popup"]',
      style: { left: '-24px' },
    },
  ]
}
