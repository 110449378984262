import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { customAxios } from '../../utils'

import { API_URL } from '@/const'
import { AdAccountsActionTypes, IAccountBody, IAdAccountsState } from '@/store/adaccounts/types'
import { ThunkResult } from '@/store/store'
import { IUserPlugin, UserActionTypes } from '@/store/user/types'
import { Platforms } from '@/types'

export enum AddMyTargetActionTypes {
  SET_ADD_MY_TARGET_LOADING = 'SET_ADD_MY_TARGET_LOADING',
  SET_ADD_MY_TARGET_ERROR = 'SET_ADD_MY_TARGET_ERROR',
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_LOADING, data: isLoading }
}

export function createMyTargetAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/mytarget/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: Platforms.MYTARGET })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_LOADING,
          data: false,
        })
      })
  }
}

export function createClientMyTargetAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/mytarget_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => response)
      .catch((error: AxiosError) => {
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_LOADING,
          data: false,
        })
      })
  }
}

export function setMyTargetManager(email: string): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/mytarget/managers/`, 'POST', { email }, true)
      .then((response: AxiosResponse<IUserPlugin>) => {
        if (response.status === 201) {
          dispatch({ type: UserActionTypes.SET_MYTARGET_MANAGER, data: true })
        }
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_ERROR,
          data: error.response?.data,
        })
        dispatch({
          type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_LOADING,
          data: false,
        })
        return Promise.reject(error)
      })
  }
}

export function setError(error: any): AnyAction {
  return { type: AddMyTargetActionTypes.SET_ADD_MY_TARGET_ERROR, data: error }
}
