import { createUseStyles } from 'react-jss'

import { BackgroundWhite, SlateGreyLighten46 } from '../../const/colors'

export default createUseStyles(
  {
    wrapper: {
      border: `1px solid ${SlateGreyLighten46}`,
      padding: 6,
      boxShadow:
        '0 16px 24px rgba(16, 23, 41, 0.04), 0 2px 6px rgba(16, 23, 41, 0.04), 0 0px 1px rgba(16, 23, 41, 0.04)',
      borderRadius: 6,
      position: 'absolute',
      top: 'calc(100% + 6px)',
      zIndex: 10,
      backgroundColor: BackgroundWhite,
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  { name: 'Popover' }
)
