export const enum MIXPANEL_SUB_MENU_KEYS {
  EDUCATION_MATERIALS_CLICK = 'EducationMaterialsClick',
  GO_TO_HELP = 'GoToHelp',
}

export const enum MIXPANEL_SUB_MENU_PARAMS {
  PLACE = 'place',
}

export const enum MIXPANEL_SUB_MENU_VALUE {
  MENU = 'menu',
}
