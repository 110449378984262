import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { BackgroundGrey, GreenBase, GreyBase, greySky2Clr, greySky3Clr, PurpleBase, White } from '@/const/colors'
import { baseFontFamily, baseTransDur, smallFont } from '@/const/commonStyles'

export const Section = styled.div<{ opened: boolean; isActive: boolean }>`
  height: 60px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  font: 500 ${smallFont} ${baseFontFamily};
  color: ${GreyBase};
  cursor: pointer;
  justify-content: space-between;
  position: relative;

  ${(props: { opened: boolean; isActive: boolean }) =>
    props.opened
      ? `
    & > div > div > div {
      background-color: ${greySky3Clr};
    }

    & > div > div > div svg path {
      fill: ${White};
    }

    & > div > svg {
      display: none;
    }

    &:hover {
      & > div > div > div {
        background-color: ${greySky2Clr};
        transition-duration: ${baseTransDur};
      }

      & > div > svg {
        display: unset;
      }
    }
  `
      : `
    ${
      props.isActive
        ? `
      color: ${PurpleBase};

      & svg path {
        fill: ${PurpleBase};
      }
    `
        : `
      & > div > div > div svg path {
        fill: ${greySky3Clr};
      }

      &:hover {
        & > div > div > div svg path {
          fill: ${greySky2Clr};
          transition-duration: ${baseTransDur};
        }
      }
    `
    }
  `}
  &:hover {
    background-color: ${BackgroundGrey};
    transition-duration: ${baseTransDur};
  }
}
`

export const SectionIcon = styled.div`
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const SectionEllipsedIcon = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const SectionSquaredIcon = styled.div`
  width: 36px;
  height: 40px;
  background-color: ${BackgroundGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
`

export const SubSection = styled.div`
  margin-bottom: 16px;
`

const SubSectionStyles = `
  height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  font: 400 ${smallFont} ${baseFontFamily};
  color: ${GreyBase};
  text-decoration: none;
  flex-shrink: 0;
  white-space: nowrap;
  position: relative;

  & svg rect,
  & svg path {
    fill: ${greySky3Clr};
  }

  &.active {
    color: ${PurpleBase};
    font-weight: 500;

    & svg rect,
    & svg path {
      fill: ${PurpleBase};
    }
  }

  &:hover {
    background-color: ${BackgroundGrey};
    transition-duration: ${baseTransDur};
  }

  &:not(.active):hover {
    & svg rect,
    & svg path {
      fill: ${greySky2Clr};
      transition-duration: ${baseTransDur};
    }
  }

  &:last-child > div > div {
    background-color: transparent;
  }

  &:first-child > div > div::before {
    content: '';
    position: absolute;
    width: 36px;
    height: 30px;
    background-color: ${BackgroundGrey};
    top: -30px;
    z-index: -1;
  }

  &:last-child > div > div::before {
    content: '';
    position: absolute;
    width: 36px;
    height: 46px;
    background-color: ${BackgroundGrey};
    top: 0;
    border-radius: 0 0 18px 18px;
    z-index: -1;
  }

   &:first-child > div > div:only-child::after {
    content: '';
    position: absolute;
    width: 36px;
    height: 30px;
    background-color: ${BackgroundGrey};
    top: -30px;
    z-index: -1;
  }
`

export const SubSectionExternalLink = styled.a`
  ${SubSectionStyles}
`

export const SubSectionLink = styled(NavLink)`
  ${SubSectionStyles}
`

export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`

export const SectionArrow = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`

export const SectionBlock = styled.div``

export const SectionExternalLink = styled.a`
  height: 60px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  font: 500 ${smallFont} ${baseFontFamily};
  color: ${GreyBase};
  text-decoration: none;

  & svg path {
    fill: ${greySky3Clr};
  }

  &:hover {
    background-color: ${BackgroundGrey};
    transition-duration: ${baseTransDur};

    & svg path {
      fill: ${greySky2Clr};
      transition-duration: ${baseTransDur};
    }
  }
`

export const NewFeatureFlag = styled.i<{ isOpened: boolean }>`
  position: absolute;
  right: 20px;
  top: calc(50% - 4px);
  display: ${(props: { isOpened: boolean }) => (props.isOpened ? 'block' : 'none')};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${GreenBase};
  pointer-events: none;
`
