import { IconTypes } from '@/common/getPlatformIcon'
import { AdvertiserCompanyType, AGENCY_STATUS, TG_PARTNER_IDS } from '@/types'

export enum MODALS {
  ADD_CONTRACT = 'addContract',
  ADD_CLIENT_CONTRACT = 'addClientContract',
}

export enum FORM_FIELDS {
  CONTRACTOR_LEGAL_STATUS = 'contractor_legal_status',
  OTHER_REGISTRATION_NUMBER = 'other_registration_number',
  OTHER_COUNTRY = 'other_country',
  OTHER_PAYMENT_NUMBER = 'other_payment_number',
  TAX_NUMBER = 'tax_number',
  RU_NAME = 'ru_name',
  PHONE_NUMBER = 'phone_number',
  RU_INN = 'ru_inn',
  RU_KPP = 'ru_kpp',
  RU_CEO = 'ru_ceo',
  RU_LEGAL_ADDRESS = 'ru_legal_address',
  OKVED = 'okved',
  OKVED_CODES = 'okved_codes',
}

export enum COUNTRY_SELECT {
  RUSSIAN = 'russia',
  OTHER = 'other',
}

export enum CONTRACTOR_TYPE {
  EXECUTOR = 'executor',
  ADVERTISER = 'advertiser',
}

export enum NetworkAccountsTypes {
  YANDEX_ACCOUNT = 'yandex_account',
  VKADS_ACCOUNT = 'vkads_account',
  VK_ACCOUNT = 'vk_account',
  MYTARGET_ACCOUNT = 'mytarget_account',
  TELEGRAMADS_ACCOUN = 'telegramads_account',
}

export enum AdsMarkingTabType {
  ACCOUNTS = 'accounts',
  CLIENT_ACCOUNTS = 'client_accounts',
  CONTRACTS = 'contracts',
  COUNTERPARTIES = 'counterparties',
}

export enum CONTRACT_TYPE {
  PROVISION_SERVICES = 'provision_of_services',
  MEDIATION = 'mediation',
  ADDITIONAL_AGREEMENT = 'additional_agreement',
}

export enum CONTRACT_SUBJECT_TYPE {
  REPRESENTATION = 'representation',
  MEDIATION = 'mediation',
  ORG_DISTRIBUTION = 'org_distribution',
  DISTRIBUTION = 'distribution',
  OTHER = 'other',
}

export enum CONTRACT_ACTION_TYPE {
  DISTRIBUTION = 'distribution',
  CONCLUDE = 'conclude',
  COMMERCIAL = 'commercial',
  OTHER = 'other',
}

export enum AddContractTypes {
  SET_CONTRACT_COUNTERPARTY = 'ADS_MARKING_SET_CONTRACT_COUNTERPARTY',
  SET_CONTRACT_COUNTERPARTY_ID = 'ADS_MARKING_SET_CONTRACT_COUNTERPARTY_ID',
  SET_CONTRACT_DETAILS = 'ADS_MARKING_SET_CONTRACT_DETAILS',
  SET_CONTRACT_DETAILS_ID = 'ADS_MARKING_SET_CONTRACT_DETAILS_ID',
  SET_CONTRACT_EXECUTOR = 'ADS_MARKING_SET_CONTRACT_EXECUTOR',
  SET_CONTRACT_EXECUTOR_ID = 'ADS_MARKING_SET_CONTRACT_EXECUTOR_ID',
  SET_CONTRACT_ADVERTISER = 'ADS_MARKING_SET_CONTRACT_ADVERTISER',
  SET_CONTRACT_ADVERTISER_ID = 'ADS_MARKING_SET_CONTRACT_ADVERTISER_ID',
  SET_MY_AGGENCY = 'ADS_MARKING_SET_MY_AGGENCY',
  CLEAR_MY_AGGENCY = 'ADS_MARKING_CLEAR_MY_AGGENCY',
  SET_CONTRACT_ACCOUNTS = 'ADS_MARKING_SET_CONTRACT_ACCOUNTS',
  SET_ACCOUNTS_ATTACHED = 'ADS_MARKING_SET_ACCOUNTS_ATTACHED',
  SET_CONTRACT_ID = 'ADS_MARKING_SET_CONTRACT_ID',
  CLEAR_CONTRACT = 'ADS_MARKING_CLEAR_CONTRACT',
  SET_LOADING = 'ADS_MARKING_CONTRACT_LOADING',
  SET_CREATE_LOADING = 'ADS_MARKING_SET_CREATE_LOADING',
  SET_NEW_MARKING_DETAILS = 'ADS_MARKING_SET_NEW_MARKING_DETAILS',
  CLEAR_NEW_MARKING_DETAILS = 'ADS_MARKING_CLEAR_NEW_MARKING_DETAILS',
}

export interface IAccountsCardProps {
  id: string
  account_type: IconTypes
  account_name: string
  account_id: string
  account_website: string
  contract: IContract | null
  advertise_object: AdvertiserCompanyType
  updateListAction: () => void
  account_partner_id: TG_PARTNER_IDS | null
  client_name?: string
  client_inn?: string
  isClient?: boolean
  profile_id?: number
  has_errors?: boolean
}

export interface IAccountsCardsProps {
  items: IAccountsCardProps[]
  updateListAction: () => void
  client_name?: string
  client_inn?: string
}

export interface IContractDetails {
  contract_type: CONTRACT_TYPE | ''
  contract_date: string
  contract_number: string
  contract_amount: string
  tax_included: boolean
  contract_subject_type: CONTRACT_SUBJECT_TYPE | ''
  contract_action_type: CONTRACT_ACTION_TYPE | ''
  id?: string
  contract_kktu?: string
  ord_errors: string[] | []
  is_client: boolean
}

export interface IContractExecutor {
  contractor_origin?: COUNTRY_SELECT
  contractor_legal_status?: string
  phone_number?: string
  ru_name?: string
  ru_inn?: string
  ru_kpp?: string
  ru_ceo?: string
  ru_legal_address?: string
  other_country?: string
  other_registration_number?: string
  other_payment_number?: string
  tax_number?: string
  contractor_type: CONTRACTOR_TYPE
  id?: string
  is_you?: boolean
  ord_errors: string[] | []
  is_client?: boolean
}

export interface IContractAdvertiser {
  contractor_type: CONTRACTOR_TYPE
  contractor_origin: COUNTRY_SELECT
  contractor_legal_status: AGENCY_STATUS
  phone_number?: string
  ru_name?: string
  ru_inn?: string
  ru_kpp?: string
  ru_ceo?: string
  ru_legal_address?: string
  other_country?: string
  other_registration_number?: string
  other_payment_number?: string
  tax_number?: string
  id?: string
  ord_errors: string[] | []
}

export enum AccountsFilterTypes {
  CONTRACT = 'contract',
  ADPLATFORM = 'ad_platform',
  AD_GROUP = 'ad_group',
  NAME = 'name',
  CLIENT = 'client',
}

export interface IProfileAdmarkInfo {
  has_errors: boolean | null
}

export interface IModal {
  name: MODALS
  data?: any
}

export interface IAccountsFilled {
  account_filled: number
  account_total: number
  has_profile_errors: boolean
  is_loading: boolean
}

export interface IAccountsListType {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: IAccountsCardProps[]
}

export interface IClientAccountsListType {
  loading: boolean
  results: IClientAccountsCardProps[]
}

export interface IContract {
  contract_details?: IContractDetails
  advertiser?: IContractAdvertiser
  executor?: IContractExecutor
  is_ready: boolean
  id?: string
}

interface IClientAccountsCardItems {
  id: string
  account_type: IconTypes
  account_name: string
  account_id: string
  account_website: string
  contract: IContract | null
  advertise_object: AdvertiserCompanyType
  updateListAction: () => void
  account_partner_id: TG_PARTNER_IDS | null
  isClient?: boolean
  profile_id: number
}

export interface IClientAccountsCardProps {
  client_id: string
  client_name: string
  client_inn: string
  client_is_valid: boolean
  updateListAction: () => void
  isClient?: boolean
  test: string
  items: IClientAccountsCardItems[]
}

export interface IClientAccountsCardsProps {
  items: IClientAccountsCardProps[]
  updateListAction: () => void
  isClient?: boolean
}

export interface IAccountsCardsProps {
  items: IAccountsCardProps[]
  updateListAction: () => void
  isClient?: boolean
  client_name?: string
  client_inn?: string
}

export interface IAccountsState {
  accountsList: IAccountsListType
  clientAccountsList: IClientAccountsListType
  accountsSelectedList: IAccountsListType
  filterQuery: string
  accountsFilled: IAccountsFilled
  modals: IModal | null
  profileAdmarkInfo: IProfileAdmarkInfo
}

export enum AccountsActionTypes {
  SET_CLIENT_ACCOUNTS = 'ADS_MARKING_SET_CLIENT_ACCOUNTS',
  UPDATE_CLIENT_ACCOUNTS = 'ADS_MARKING_UPDATE_CLIENT_ACCOUNTS',
  SET_CLIENT_ACCOUNTS_LOADING = 'ADS_MARKING_SET_CLIENT_ACCOUNTS_LOADING',
  CLEAR_CLIENT_ACCOUNTS = 'ADS_MARKING_CLEAR_CLIENT_ACCOUNTS',

  SET_ACCOUNTS = 'ADS_MARKING_SET_ACCOUNTS',
  UPDATE_ACCOUNTS = 'ADS_MARKING_UPDATE_ACCOUNTS',
  SET_ACCOUNTS_LOADING = 'ADS_MARKING_SET_ACCOUNTS_LOADING',
  CLEAR_ACCOUNTS = 'ADS_MARKING_CLEAR_ACCOUNTS',
  SET_ACCOUNTS_FILTER_QUERY = 'ADS_MARKING_SET_ACCOUNTS_FILTER_QUERY',
  CLEAR_ACCOUNTS_FILTER_QUERY = 'ADS_MARKING_CLEAR_ACCOUNTS_FILTER_QUERY',
  SET_ACCOUNTS_FILLED = 'ADS_MARKING_SET_ACCOUNTS_FILLED',
  SET_ACCOUNTS_FILLED_LOADING = 'ADS_MARKING_SET_ACCOUNTS_FILLED_LOADING',
  SET_ACCOUNTS_PROFILE_ADMARK_INFO = 'ADS_MARKING_SET_ACCOUNTS_PROFILE_ADMARK_INFO',
}

export enum AccountsFormsActionTypes {
  SET_ACCOUNTS_SELECTED = 'ADS_MARKING_SET_ACCOUNTS_SELECTED',
  SET_ACCOUNTS_SELECTED_LOADING = 'ADS_MARKING_SET_ACCOUNTS_SELECTED_LOADING',
}

export interface IUpdateAccounts {
  account_ids: number[] | string[]
  advertise_object: AdvertiserCompanyType
  kktu?: string
}

export const enum MIXPANEL_ADS_MARKING_KEYS {
  MARKING_ACCOUNT_ADD_CONTRACT_MODEST_SAVE = 'MarkingAccountAddcontractmodestSave',
  MARKING_INSTRUCTION = 'MarkingInstruction',
  MARKING_ACCOUNT_MENU_CLICK = 'MarkingAccountMenuClick',
  MARKING_CLIENT_ACCOUNTS_MENU_CLICK = 'MarkingClientAccountsMenuClick',
  MARKING_ACCOUNT_ADD_CONTRACT_CLICK = 'MarkingAccountAddcontractClick',
  MARKING_ACCOUNT_ADD_CONTRACT_CONTRACTOR_SAVE = 'MarkingAccountAddcontractContractorSave',
  MARKING_VISIT = 'MarkingVisit',
  MARKING_ACCOUNT_ADD_CONTRACT_MODEST_CHOOSE = 'MarkingAccountAddcontractmodestChoose',
  MARKING_ACCOUNT_ADD_CONTRACT_MODEST_NEXT = 'MarkingAccountAddcontractmodestNext',
  MARKING_ACCOUNT_ADD_CONTRACT_REQUISITES_CLICK = 'MarkingAccountAddcontractRequisitesClick',
  MARKING_ACCOUNT_ADD_CONTRACT_ADVERTISER_CLICK = 'MarkingAccountAddcontractAdvertiserClick',
  MARKING_ACCOUNT_ADD_CONTRACT_CONTRACTOR_CLICK = 'MarkingAccountAddcontractContractorClick',
  MARKING_ACCOUNT_ADD_CONTRACT_CONTRACTOR_SAVE_AND_RETURN = 'MarkingAccountAddcontractContractorSaveandReturn',
  MARKING_ACCOUNT_ADD_CONTRACT_ADVERTISER_SAVE = 'MarkingAccountAddcontractAdvertiserSave',
  MARKING_ACCOUNT_ADD_CONTRACT_REQUISITES_SAVE = 'MarkingAccountAddcontractRequisitesSave',
}

export const enum MIXPANEL_ADS_MARKING_PARAMS {
  MODEST = 'modest',
  PLACE = 'place',
  TYPE_OF_ACCOUNT = 'type_of_account',
}

export const enum MIXPANEL_ADS_MARKING_VALUE {
  ACCOUNT = 'account',
  LIST = 'list',
  NEW = 'new',
  MAIN = 'main',
  CONTRACT = 'contract',
  CONTRACTOR = 'contractor',
  AGENCY_ACCOUNTS = 'agency_accounts',
  AGENCIES_CLIENTS_ACCOUNTS = 'agencies_clients_accounts',
  AGENCIES_DIRECT_MANIPULATION_ACCOUNTS = 'agencies_direct_manipulation_accounts',
}

export type MixpanelTypeOfAccount =
  | MIXPANEL_ADS_MARKING_VALUE.AGENCY_ACCOUNTS
  | MIXPANEL_ADS_MARKING_VALUE.AGENCIES_CLIENTS_ACCOUNTS
  | MIXPANEL_ADS_MARKING_VALUE.AGENCIES_DIRECT_MANIPULATION_ACCOUNTS
