import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createResponsiveStateReducer } from 'redux-responsive'

import addMyTarget from './pages/AddMyTarget/reducer'
import addTelegram from './pages/AddTelegram/reducer'
import addVK from './pages/AddVK/reducer'
import addVKAds from './pages/AddVKAds/reducer'
import addVKAdsMarket from './pages/AddVKAdsMarket/reducer'
import addYandex from './pages/AddYandex/reducer'
import addYandexPromo from './pages/AddYandexPromo/reducer'
import AdsMarkingAddContractReducer from './pages/AdsMarking/AddContractRoot/reducer'
import AdsMarkingContractsReducer from './pages/AdsMarking/ContractsRoot/reducer'
import AdsMarkingCounterpartiesReducer from './pages/AdsMarking/CounterpartiesRoot/reducer'
import AdsMarkingAccountsReducer from './pages/AdsMarking/reducer'
import analytics from './pages/Analytics/reducer'
import budgetRootReducer from './pages/Budget/reducer'
import campaignEditorReducer from './pages/CampaignEditor/reducer'
import confirmClient from './pages/ConfirmClient/reducer'
import marketplaceReducer from './pages/FreeServices/reducer'
import importVK from './pages/ImportVK/reducer'
import { accountingReducer } from './store/accounting'
import { adAccountsReducer } from './store/adaccounts'
import { adPlatformsReducer } from './store/adplatforms'
import { antibanReducer } from './store/antiban'
import { appReducer } from './store/app'
import { audiencesReducer } from './store/audiences'
import { budgetReducer } from './store/budget'
import { clientsReducer } from './store/clients'
import { documentsReducer } from './store/documents'
import { feedsReducer } from './store/feeds'
import { invoicesReducer } from './store/invoices'
import { myCashbackReducer } from './store/my-cashback'
import { notificationsReducer } from './store/notifications'
import { onboardingReducer } from './store/onboarding'
import { pollsReducer } from './store/polls'
import { promoReducer } from './store/promo'
import { promocodeReducer } from './store/promocodes'
import { referralReducer } from './store/referral'
import { storiesReducer } from './store/stories'
import { transactionReducer } from './store/transactions'
import { userReducer } from './store/user'

const browserReducer = createResponsiveStateReducer(
  {
    container: 1170,
    desktop: 1279,
    extraSmall: 480,
    large: 1439,
    medium: 1023,
    small: 768,
  },
  {
    extraFields: () => ({
      width: window.innerWidth,
    }),
  }
)

export default (history: any) =>
  combineReducers({
    analytics,
    importVK,
    form: formReducer,
    accounting: accountingReducer,
    adAccounts: adAccountsReducer,
    antiban: antibanReducer,
    app: appReducer,
    adsMarkingAccounts: AdsMarkingAccountsReducer,
    adsMarkingContracts: AdsMarkingContractsReducer,
    adsMarkingCounterparties: AdsMarkingCounterpartiesReducer,
    adsMarkingAddContract: AdsMarkingAddContractReducer,
    audiences: audiencesReducer,
    balances: budgetReducer,
    browser: browserReducer,
    clients: clientsReducer,
    campaignEditor: campaignEditorReducer,
    confirmClient,
    documents: documentsReducer,
    feeds: feedsReducer,
    invoices: invoicesReducer,
    myCashback: myCashbackReducer,
    notifications: notificationsReducer,
    onboarding: onboardingReducer,
    promo: promoReducer,
    promocode: promocodeReducer,
    referral: referralReducer,
    router: connectRouter(history),
    stories: storiesReducer,
    polls: pollsReducer,
    adPlatforms: adPlatformsReducer,
    marketplace: marketplaceReducer,
    transactions: transactionReducer,
    user: userReducer,
    addTelegram,
    addYandexPromo,
    budget: budgetRootReducer,
    addVKAdsMarket,
    addVKAds,
    addMyTarget,
    addVK,
    addYandex,
  })
