import React from 'react'

import { GreenDarken22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreenDarken22, fillBg = 'none' } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.917 8.26437V5.34214C11.917 4.56712 11.6091 3.82384 11.0611 3.27582C10.5131 2.7278 9.76982 2.41992 8.99479 2.41992C8.21977 2.41992 7.4765 2.7278 6.92847 3.27582C6.58404 3.62026 6.33446 4.04182 6.19657 4.49992M3.15039 9.72548C3.15039 9.33797 3.30433 8.96633 3.57834 8.69232C3.85235 8.41831 4.22399 8.26437 4.6115 8.26437H13.3782C13.7657 8.26437 14.1373 8.41831 14.4113 8.69232C14.6853 8.96633 14.8393 9.33797 14.8393 9.72548V14.1088C14.8393 14.4963 14.6853 14.868 14.4113 15.142C14.1373 15.416 13.7657 15.5699 13.3782 15.5699H4.6115C4.22399 15.5699 3.85235 15.416 3.57834 15.142C3.30433 14.868 3.15039 14.4963 3.15039 14.1088V9.72548Z"
          stroke={fill}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="18" height="18" />
      </svg>
    </svg>
  )
}
