import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 28, height: 28 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5346 6.19164C9.87895 5.40115 7.56473 5.01392 4.46643 5.00017C4.17423 4.9962 3.88777 5.0814 3.64523 5.24442C3.44616 5.37898 3.2832 5.56039 3.17069 5.77271C3.05818 5.98502 2.99957 6.22173 3 6.46202V19.5728C3 20.4591 3.63057 21.1277 4.46643 21.1277C7.72329 21.1277 10.9902 21.432 12.947 23.2815C12.9738 23.307 13.0074 23.3239 13.0438 23.3304C13.0801 23.3369 13.1175 23.3325 13.1514 23.3178C13.1853 23.3032 13.2141 23.2789 13.2343 23.248C13.2545 23.2171 13.2652 23.181 13.265 23.1441V7.69565C13.2651 7.59143 13.2428 7.48841 13.1996 7.39354C13.1564 7.29868 13.0934 7.21418 13.0148 7.14574C12.5666 6.76256 12.0687 6.44163 11.5346 6.19164ZM24.3513 5.24305C24.1086 5.08043 23.8221 4.9957 23.5301 5.00017C20.4318 5.01392 18.1175 5.39931 16.4619 6.19164C15.9278 6.44117 15.4298 6.76147 14.9812 7.14391C14.9028 7.21244 14.8399 7.29697 14.7968 7.39182C14.7537 7.48667 14.7314 7.58965 14.7315 7.69382V23.1431C14.7314 23.1786 14.7419 23.2133 14.7615 23.2429C14.7811 23.2724 14.809 23.2955 14.8417 23.3093C14.8744 23.323 14.9104 23.3268 14.9453 23.3202C14.9801 23.3135 15.0122 23.2967 15.0376 23.2719C16.2139 22.1034 18.2784 21.1263 23.5319 21.1268C23.9208 21.1268 24.2938 20.9723 24.5688 20.6973C24.8438 20.4223 24.9983 20.0493 24.9983 19.6604V6.46248C24.9989 6.22172 24.9401 5.98453 24.8273 5.77186C24.7144 5.55919 24.5509 5.37757 24.3513 5.24305Z"
        fill={fill}
      />
    </svg>
  )
}
