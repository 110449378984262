import React, { ReactNode } from 'react'
import cn from 'classnames'

import useStyles from './styles'

interface IPopoverProps {
  children: ReactNode
  className?: string
}

const Popover = (props: IPopoverProps) => {
  const { children, className } = props

  const classes = useStyles()

  return <div className={cn(classes.wrapper, className)}>{children}</div>
}

export default Popover
