import { Reducer } from 'redux'

import { Pages } from '../../types'
import { ClientsActionTypes, IClientsState } from './types'

const initialState: IClientsState = {
  clientAccounts: null,
  clients: [],
  loading: false,
  pagination_object: {
    count: 0,
    current: 0,
    next: null,
    page_count: 0,
    previous: null,
    results: [],
  },
  requests: [],
}

const reducer: Reducer<IClientsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case ClientsActionTypes.SET_CLIENTS: {
      const data = action.data

      return {
        ...state,
        clients: action.page === Pages.FIRST ? [...data.results] : [...state.clients, ...data.results],
        loading: false,
        pagination_object: data,
      }
    }

    case ClientsActionTypes.SET_CLIENT_ACCOUNTS: {
      const data = action.data
      const clientAccounts = {
        [data.clientId]: data.accounts,
      }

      return {
        ...state,
        clientAccounts: {
          clientProfile: data.profile,
          ...state.clientAccounts,
          ...clientAccounts,
        },
      }
    }

    case ClientsActionTypes.SET_CLIENT_REQUESTS: {
      return {
        ...state,
        requests: action.data,
      }
    }

    case ClientsActionTypes.SET_CLIENT_IS_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }

    default: {
      return state
    }
  }
}

export { reducer as clientsReducer }
