import { Reducer } from 'redux'

import {
  IReferral,
  IReferralBonusesData,
  IReferralStatisticsData,
  reducerModalName,
  ReferralActionTypes,
} from './types'

const initialStatisticsState: IReferralStatisticsData = {
  paid_bonuses: '0.00',
  paid_profiles: 0,
  spending_profiles: 0,
  all_activated: 0,
}

const initialBonusesState: IReferralBonusesData = {
  accrued_bonuses: '0.00',
  is_ready_to_withdraw: false,
  processed_bonuses: '0.00',
  counted_referrals: 0,
}

export const initialState: IReferral = {
  promo: {
    code: '',
  },
  statistics: {
    data: initialStatisticsState,
    isLoading: false,
  },
  bonuses: {
    data: initialBonusesState,
    isLoading: false,
  },
  modals: null,
  details: {
    data: {
      details: '',
    },
    isLoading: false,
  },
}

const reducer: Reducer<IReferral> = (state = initialState, action: any) => {
  switch (action.type) {
    case ReferralActionTypes.SET_REFERRAL_PROMO_CODE: {
      if (action.data?.code) {
        return { ...state, promo: { ...state.promo, code: action.data.code } }
      }
      return initialState
    }
    case ReferralActionTypes.SET_REFERRAL_STATISTICS: {
      return { ...state, statistics: { ...state.statistics, data: { ...state.statistics.data, ...action.data } } }
    }
    case ReferralActionTypes.SET_REFERRAL_STATISTICS_LOADING: {
      return { ...state, statistics: { ...state.statistics, isLoading: action.data } }
    }
    case ReferralActionTypes.SET_REFERRAL_BONUSES: {
      return { ...state, bonuses: { ...state.bonuses, data: { ...state.bonuses.data, ...action.data } } }
    }
    case ReferralActionTypes.SET_REFERRAL_BONUSES_LOADING: {
      return { ...state, bonuses: { ...state.bonuses, isLoading: action.data } }
    }
    case ReferralActionTypes.SET_REFERRAL_DETAILS: {
      return { ...state, details: { ...state.details, data: { ...state.details.data, ...action.data } } }
    }
    case ReferralActionTypes.SET_REFERRAL_DETAILS_LOADING: {
      return { ...state, details: { ...state.details, isLoading: action.data } }
    }
    case `${reducerModalName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerModalName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as referralReducer }
