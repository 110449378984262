import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    productTourButton: {
      display: 'flex',
      marginTop: 16,
    },
  },
  { name: 'EducationUtils' }
)
