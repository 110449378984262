import React from 'react'

import {
  AvitoIcon as SMBAvitoIcon,
  FacebookIcon as SMBFacebookIcon,
  MTSIcon,
  MTSIcon as SMBMTSIcon,
  MyTargetIcon as SMBMyTargetIcon,
  RoubleIcon as SMBRoubleIcon,
  TelegramIcon as SMBTelegramIcon,
  TikTokIcon as SMBTikTokIcon,
  VKAdsIcon as SMBVKAdsIcon,
  VKAdsIcon,
  VKAdsMarketIcon as SMBVKAdsMarketIcon,
  VKIcon as SMBVKIcon,
  YandexIcon as SMBYandexIcon,
  YandexPromoPageIcon as SMBYandexPromoPageIcon,
  YandexPromoPageIcon,
} from '../components/Icons'
import AvitoIcon from '../components/Icons/AvitoIcon'
import FacebookIcon from '../components/Icons/FacebookIcon'
import MyTargetIcon from '../components/Icons/MyTargetIcon'
import RoubleIcon from '../components/Icons/RoubleIcon'
import TelegramIcon from '../components/Icons/TelegramIcon'
import TikTokIcon from '../components/Icons/TikTokIcon'
import VKAdsMarketIcon from '../components/Icons/VKAdsMarketIcon'
import VKIcon from '../components/Icons/VKIcon'
import YandexIcon from '../components/Icons/YandexIcon'

export enum IconTypes {
  FACEBOOK = 'facebook',
  ROUBLE = 'rouble',
  VK = 'vk',
  MYTARGET = 'mytarget',
  TIKTOK = 'tiktok',
  YANDEX = 'yandex',
  AVITO = 'avito',
  BALANCE = 'balance',
  TELEGRAM = 'telegram',
  VK_ADS = 'vkads',
  YANDEX_PROMO_PAGE = 'yandex_promo_page',
  VK_ADS_MARKET = 'vk_adsmarket',
}

export const getPlatformIcon = (iconType: IconTypes | string | null, isTGMTSIcon?: boolean) => {
  switch (iconType) {
    case IconTypes.FACEBOOK:
      return <FacebookIcon />
    case IconTypes.ROUBLE:
      return <RoubleIcon />
    case IconTypes.VK:
      return <VKIcon />
    case IconTypes.MYTARGET:
      return <MyTargetIcon />
    case IconTypes.TIKTOK:
      return <TikTokIcon />
    case IconTypes.YANDEX:
      return <YandexIcon />
    case IconTypes.YANDEX_PROMO_PAGE:
      return <YandexPromoPageIcon />
    case IconTypes.AVITO:
      return <AvitoIcon />
    case IconTypes.BALANCE:
      return <RoubleIcon />
    case IconTypes.TELEGRAM:
      return isTGMTSIcon ? <MTSIcon /> : <TelegramIcon />
    case IconTypes.VK_ADS:
      return <VKAdsIcon />
    case IconTypes.VK_ADS_MARKET:
      return <VKAdsMarketIcon />
    default:
      return null
  }
}

export const getSMBPlatformIcon = (iconType: IconTypes | string | null, isTGMTSIcon?: boolean) => {
  switch (iconType) {
    case IconTypes.FACEBOOK:
      return <SMBFacebookIcon />
    case IconTypes.ROUBLE:
      return <SMBRoubleIcon />
    case IconTypes.VK:
      return <SMBVKIcon />
    case IconTypes.MYTARGET:
      return <SMBMyTargetIcon />
    case IconTypes.TIKTOK:
      return <SMBTikTokIcon />
    case IconTypes.YANDEX:
      return <SMBYandexIcon />
    case IconTypes.YANDEX_PROMO_PAGE:
      return <SMBYandexPromoPageIcon />
    case IconTypes.AVITO:
      return <SMBAvitoIcon />
    case IconTypes.BALANCE:
      return <SMBRoubleIcon />
    case IconTypes.TELEGRAM:
      return isTGMTSIcon ? <SMBMTSIcon /> : <SMBTelegramIcon />
    case IconTypes.VK_ADS:
      return <SMBVKAdsIcon />
    case IconTypes.VK_ADS_MARKET:
      return <SMBVKAdsMarketIcon />
    default:
      return null
  }
}
