import { AnyAction, Dispatch } from 'redux'

import { AdPlatformsActionTypes } from './types'

import { API_URL } from '@/const'
import { customAxios } from '@/utils'

export function setAdPlatforms(data: any) {
  return { type: AdPlatformsActionTypes.SET_AD_PLATFORMS, data }
}

export function setAdPlatformsLoading(isLoading: boolean) {
  return { type: AdPlatformsActionTypes.SET_IS_LOADING, isLoading }
}

export function setAdPlatformsRequestError(data: any) {
  return { type: AdPlatformsActionTypes.SET_REQUEST_ERROR, data }
}

export function removeAdPlatformsRequestError() {
  return { type: AdPlatformsActionTypes.REMOVE_REQUEST_ERROR }
}

export const getAdPlatforms = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const url = `${API_URL}/adplatforms/`
    try {
      dispatch(setAdPlatformsLoading(true))
      const response = await customAxios(url, 'GET', null, true)
      dispatch(setAdPlatforms(response.data))
      return response
    } catch (error) {
      dispatch(setAdPlatformsRequestError(error))
      return Promise.reject(error)
    } finally {
      dispatch(setAdPlatformsLoading(false))
    }
  }
}
