import { Reducer } from 'redux'

import { AddVKActionTypes } from './actions'
import { IAddVKState } from './types'

export const initialState: IAddVKState = {
  error: null,
  isLoading: false,
}

const addVKReducer: Reducer<IAddVKState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddVKActionTypes.SET_ADD_VK_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddVKActionTypes.SET_ADD_VK_ERROR: {
      return { ...state, error: action.data }
    }
    default: {
      return state
    }
  }
}

export default addVKReducer
