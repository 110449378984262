import { createUseStyles } from 'react-jss'

import { GreyBase, SlateGreyLighten46, TextGreyBase } from '../../const/colors'
import { FontBody1, FontBody1Accent, fontFamily, FontH4 } from '../../const/fontStyles'

export default createUseStyles(
  {
    wrapper: {
      fontFamily: fontFamily.Base,
      border: `1px solid ${SlateGreyLighten46}`,
      padding: '24px 23px',
      outline: 'none',
      maxWidth: 460,
      width: 'calc(100vw - 30px)',

      '& .reactour__close': {
        outline: 'none',
        width: 14,
        height: 14,
        color: GreyBase,
        top: 24,
        right: 24,
      },

      "& [data-tour-elem='controls']": {
        display: 'flex',
        justifyContent: 'space-between',
      },

      "& [data-tour-elem='controls'] button": {
        color: TextGreyBase,
        outline: 'none',
      },

      "& [data-tour-elem='dot']": {
        transform: 'none',
        margin: 1,
      },

      "& [data-tour-elem='dot']:not(.reactour__dot--is-active)": {
        border: '1px solid #dadce0',
      },

      "& [data-tour-elem='dot'].reactour__dot--is-active": {
        borderColor: '#7d25fb',
        backgroundColor: '#7d25fb',
      },

      "& [data-tour-elem='dot']:not(.reactour__dot--is-active):hover": {
        backgroundColor: '#7d25fb',
      },

      '& h4': {
        margin: '0 0 24px',
        ...FontH4,
        color: TextGreyBase,
      },

      '& p': {
        margin: '0 0 8px',
        ...FontBody1,
        color: TextGreyBase,
      },

      '& p:last-of-type': {
        margin: 0,
      },

      '& #product-tour-button': {
        fontFamily: fontFamily.Base,
        width: 159,
        height: 45,
        backgroundColor: '#0ac738',
        borderRadius: 4,
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        marginTop: 24,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
      },

      '@media (min-width: 490px)': {
        width: 460,
      },

      '& #accent-text': {
        ...FontBody1Accent,
      },
    },
  },
  { name: 'ProductTour' }
)
