import { Reducer } from 'redux'

import { AddMyTargetActionTypes } from './actions'
import { IAddMyTargetState } from './types'

export const initialState: IAddMyTargetState = {
  error: null,
  isLoading: false,
}

const addMyTargetReducer: Reducer<IAddMyTargetState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddMyTargetActionTypes.SET_ADD_MY_TARGET_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddMyTargetActionTypes.SET_ADD_MY_TARGET_ERROR: {
      return { ...state, error: action.data }
    }
    default: {
      return state
    }
  }
}

export default addMyTargetReducer
