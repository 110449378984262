import { AxiosError, AxiosResponse, Method } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { ThunkResult } from '../store'
import {
  IReferralBonusesData,
  IReferralDetailsBody,
  IReferralPromoCode,
  IReferralStatisticsData,
  reducerModalName,
  ReferralActionTypes,
} from './types'

import { closeModal, openModal } from '@/common/actions'
import { API_URL } from '@/const'
import { customAxios } from '@/utils'

export const openReferralModal = openModal(reducerModalName)
export const closeReferralModal = closeModal(reducerModalName)

export function getReferralCode(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/promocodes/referral/`, 'GET', null, true)
      .then((response: AxiosResponse<IReferralPromoCode>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_PROMO_CODE, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setReferralCode(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/promocodes/referral/`, 'POST', {}, true)
      .then((response: AxiosResponse<IReferralPromoCode>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_PROMO_CODE, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getReferralStatistics(month?: string, year?: string, showLoader = true): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    if (showLoader) {
      dispatch(setReferralStatisticsLoading(true))
    }
    let queryParams = ''
    let url = `${API_URL}/promocodes/referral/statistics/`

    if (month && year) {
      queryParams = `?filter_month=${month}&filter_year=${year}`
    }

    if (queryParams) {
      url = `${url}${queryParams}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IReferralStatisticsData>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_STATISTICS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        if (showLoader) {
          dispatch(setReferralStatisticsLoading(false))
        }
      })
  }
}

export function getReferralBonuses(showLoader = true): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    if (showLoader) {
      dispatch(setReferralBonusesLoading(true))
    }
    return customAxios(`${API_URL}/promocodes/referral/bonuses/`, 'GET', null, true)
      .then((response: AxiosResponse<IReferralBonusesData>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_BONUSES, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        if (showLoader) {
          dispatch(setReferralBonusesLoading(false))
        }
      })
  }
}

export function getReferralDetails(showLoader = true): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    if (showLoader) {
      dispatch(setReferralDetailsLoading(true))
    }
    return customAxios(`${API_URL}/invoices/profile_account_details/`, 'GET', null, true)
      .then((response: AxiosResponse<IReferralDetailsBody>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_DETAILS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        if (showLoader) {
          dispatch(setReferralDetailsLoading(false))
        }
      })
  }
}

export function setReferralDetails(
  body: IReferralDetailsBody,
  isCreation = true,
  showLoader = true
): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const method: Method = isCreation ? 'POST' : 'PATCH'

    if (showLoader) {
      dispatch(setReferralDetailsLoading(true))
    }
    return customAxios(`${API_URL}/invoices/profile_account_details/`, method, body, true)
      .then((response: AxiosResponse<IReferralDetailsBody>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL_DETAILS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        if (showLoader) {
          dispatch(setReferralDetailsLoading(false))
        }
      })
  }
}

export function withdrawReferralBonus(): ThunkResult<Promise<any>> {
  return () => {
    return customAxios(`${API_URL}/promocodes/referral/bonuses/`, 'POST', {}, true)
      .then((response: AxiosResponse) => {
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setReferralStatisticsLoading(isLoading: boolean): AnyAction {
  return { type: ReferralActionTypes.SET_REFERRAL_STATISTICS_LOADING, data: isLoading }
}

export function setReferralBonusesLoading(isLoading: boolean): AnyAction {
  return { type: ReferralActionTypes.SET_REFERRAL_BONUSES_LOADING, data: isLoading }
}

export function setReferralDetailsLoading(isLoading: boolean): AnyAction {
  return { type: ReferralActionTypes.SET_REFERRAL_DETAILS_LOADING, data: isLoading }
}
