export enum AdPlatformsActionTypes {
  SET_IS_LOADING = 'AD_PLATFORMS_SET_IS_LOADING',
  SET_REQUEST_ERROR = 'AD_PLATFORMS_SET_REQUEST_ERROR',
  REMOVE_REQUEST_ERROR = 'AD_PLATFORMS_REMOVE_REQUEST_ERROR',
  SET_AD_PLATFORMS = 'AD_PLATFORMS_SET_DATA',
}

export interface IRequestErrorData {
  isError: boolean
  error: any
}

export interface IAdPlatform {
  title: string
  slug: string
  icon: string
  url: string
  isActive: boolean
}

export interface IAdPlatformsList {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  results: IAdPlatform[]
}

export interface IAdPlatformsState {
  adPlatformsList: IAdPlatformsList
  isLoading: boolean
  requestError: IRequestErrorData
}
