import { closeModal, openModal } from '@/common/actions'

export const reducerName = 'adsMarkingAccounts.modals'

export const openAdsMarkingAccountsModal = openModal(reducerName)
export const closeAdsMarkingAccountsModal = closeModal(reducerName)

import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { getAccountsFilled } from '../actions'
import { ContractsFormsActionTypes } from '../ContractsRoot/types'
import { AccountsActionTypes, AccountsFormsActionTypes, IAccountsListType } from '../types'

import { getPlatformIcon } from '@/common/getPlatformIcon'
import { IComboBoxOption } from '@/components/ComboBox'
import { API_URL } from '@/const'
import { MyThunkDispatch } from '@/store/store'
import { TG_MTS_PARTNERS } from '@/types'
import { customAxios } from '@/utils'

export function getSelectedAccounts(page: number) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: AccountsFormsActionTypes.SET_ACCOUNTS_SELECTED_LOADING })

    const url = `${API_URL}/ad_mark/accounts/?page=${page}`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: AccountsFormsActionTypes.SET_ACCOUNTS_SELECTED, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getSelectedContracts(clientId?: string) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: ContractsFormsActionTypes.SET_CONTRACTS_SELECTED_LOADING })

    let url = `${API_URL}/ad_mark/contracts/?page=page_all`

    if (clientId) {
      url = `${url}&q=&client_id=${clientId}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: ContractsFormsActionTypes.SET_CONTRACTS_SELECTED, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setContractsRequest(contractId: string, accounts: number[]) {
  return (dispatch: MyThunkDispatch): AxiosPromise<any> => {
    const url = `${API_URL}/ad_mark/contracts/${contractId}/`

    return customAxios(url, 'PATCH', { accounts }, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: AccountsActionTypes.CLEAR_ACCOUNTS })
        dispatch(getAccountsFilled())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export const getSearchAccountRequest = (inputValue: string, callback: (options: IComboBoxOption[]) => void) => {
  const url = `${API_URL}/ad_mark/accounts/?name=${inputValue}`

  customAxios(url, 'GET', null, true).then((response: AxiosResponse<IAccountsListType>) => {
    const normalizedAccounts = response.data.results.map(
      ({ account_name, account_type, account_id, id, account_partner_id }) => {
        const isTGMTSIcon = !!account_partner_id && TG_MTS_PARTNERS.includes(account_partner_id)

        return {
          value: id.toString(),
          label: account_name,
          subLabel: `ID ${account_id}`,
          icon: getPlatformIcon(account_type, isTGMTSIcon),
        }
      }
    )
    callback(normalizedAccounts)
  })
}
