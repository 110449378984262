import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import cn from 'classnames'

import ReferralButton from '../ReferralButton'
import { MIXPANEL_SUB_MENU_KEYS, MIXPANEL_SUB_MENU_PARAMS, MIXPANEL_SUB_MENU_VALUE } from './constants'
import selector, {
  IsNotViewableAdsMarkingFeature,
  IsNotViewableCampaignEditorTelegramFeature,
  IsNotViewableDashboardsFeature,
  IsNotViewableFinancialTermsLink,
  IsNotViewableFreeServicesFeature,
  IsNotViewableImportVKFeature,
  IsNotViewableReportFeature,
  IsNotViewableTelegramFeature,
  IsNotViewableTrackerFeature,
  IsNotViewableVKAdsFeature,
} from './selector'
import {
  NewFeatureFlag,
  Section,
  SectionArrow,
  SectionBlock,
  SectionEllipsedIcon,
  SectionIcon,
  SectionInfo,
  SectionSquaredIcon,
  SubSection,
  SubSectionExternalLink,
  SubSectionLink,
} from './styled'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import {
  AdsMarkingIcon,
  ArrowUpIcon,
  BidManagerIcon,
  BillIcon,
  CampaignEditorIcon,
  CapIcon,
  ChartPieIcon,
  ClockFilledIcon,
  CoinsIcon,
  CycleIcon,
  DashboardIcon,
  DocIcon,
  FreeServicesIcon,
  HomeIcon,
  InviteFriendIcon,
  PercentFilledIcon,
  QuestionFilledIcon,
  ReportIcon,
  RocketIcon,
  SocialBigLogoIcon,
  TrackerIcon,
  VKShopIcon,
} from '@/components/Icons'
import { SuggestCompanyTypes } from '@/const'
import { GreyLighten26 } from '@/const/colors'
import { TRANSITION_TIMEOUT } from '@/const/commonStyles'
import { IsNotViewableEducationMaterialsFeature } from '@/const/localStorage'
import { Routes } from '@/const/routes'
import { toggleAnalyticsMenu, toggleEducationMenu, toggleFinanceMenu, toggleToolMenu } from '@/store/app'
import { setToStorage } from '@/utils/localStorage'

const LANDING_HELP_URL = 'https://www.onespot.one/help'
const BID_MANAGER_URL = 'https://onespot.one/bid-manager'
const FINANCIAL_TERMS_URL = 'https://files.onespot.one/smb/financial_terms.pdf'

const SubMenu = () => {
  const {
    isFinanceMenuOpened,
    isProfileClient,
    isToolMenuOpened,
    isMenuOpened,
    isNotViewableDashboardsFeature,
    isNotViewableEducationMaterialsFeature,
    isNotViewableReportFeature,
    isNotViewableReferral,
    isNotViewableImportVKFeature,
    isNotViewableFinancialTermsLink,
    isNotViewableFreeServicesFeature,
    profileType,
    isAnalyticsMenuOpened,
    isEducationMenuOpened,
    isNotViewableTrackerFeature,
    isNotViewableTelegramFeature,
    isNotViewableVKAdsFeature,
    isViewAdsMarking,
    isNotViewableCampaignEditorTelegramFeature,
    isNotViewableAdsMarkingFeature,
    isGreaterThanDesktop,
  } = useSelector(selector)

  const [isCampaignEditorNewFeature, setIsCampaignEditorNewFeature] = useState(
    isNotViewableCampaignEditorTelegramFeature
  )

  const [isAdsMarkingNewFeature, setIsAdsMarkingNewFeature] = useState(isNotViewableAdsMarkingFeature)

  const [isFinancialTermsLink, setIsFinancialTermsLink] = useState(isNotViewableFinancialTermsLink)
  const [isFreeServicesNewFeature, setIsFreeServicesNewFeature] = useState(isNotViewableFreeServicesFeature)

  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const onFinanceMenuToggle = () => {
    dispatch(toggleFinanceMenu(!isFinanceMenuOpened))

    Mixpanel.track('ToggleFinanceMenu')
  }

  const onToolMenuToggle = () => {
    dispatch(toggleToolMenu(!isToolMenuOpened))

    Mixpanel.track('ToggleToolMenu')
  }

  const onAnalyticsMenuToggle = () => {
    dispatch(toggleAnalyticsMenu(!isAnalyticsMenuOpened))

    Mixpanel.track('ToggleAnalyticsMenu')
  }

  const onEducationMenuToggle = () => {
    dispatch(toggleEducationMenu(!isEducationMenuOpened))

    Mixpanel.track('ToggleEducationMenu')
  }

  const isFinanceMenuActive = () => {
    return (
      !isFinanceMenuOpened &&
      (location.pathname.includes(Routes.INVOICES) ||
        location.pathname.includes(Routes.HISTORY) ||
        location.pathname.includes(Routes.BUDGET))
    )
  }

  const isToolMenuActive = () => {
    return (
      !isToolMenuOpened &&
      (location.pathname.includes(Routes.AUDIENCES) ||
        location.pathname.includes(Routes.OPTIMIZER) ||
        location.pathname.includes(Routes.ANTIBAN) ||
        location.pathname.includes(Routes.FEEDMANAGER) ||
        location.pathname.includes(Routes.IMPORT_VK) ||
        location.pathname.includes(Routes.FREE_SERVICES) ||
        location.pathname.includes(Routes.CAMPAIGN_EDITOR) ||
        location.pathname.includes(Routes.ADS_MARKING))
    )
  }

  const isAnalyticsMenuActive = () => {
    return (
      !isAnalyticsMenuOpened &&
      (location.pathname.includes(Routes.ANALYTICS) ||
        location.pathname.includes(Routes.TRACKER) ||
        location.pathname.includes(Routes.REPORTS))
    )
  }

  const isEducationMenuActive = () => {
    return !isEducationMenuOpened && location.pathname.includes(Routes.EDUCATION)
  }

  const onEducationMaterialsClick = () => {
    Mixpanel.track(MIXPANEL_SUB_MENU_KEYS.EDUCATION_MATERIALS_CLICK, {
      [MIXPANEL_SUB_MENU_PARAMS.PLACE]: MIXPANEL_SUB_MENU_VALUE.MENU,
    })

    setToStorage(IsNotViewableEducationMaterialsFeature, false)
  }

  const onBudgetClick = () => {
    Mixpanel.track('AccountsGotoBudget')
  }

  const onSupportClick = () => {
    Mixpanel.track(MIXPANEL_SUB_MENU_KEYS.GO_TO_HELP, {
      [MIXPANEL_SUB_MENU_PARAMS.PLACE]: MIXPANEL_SUB_MENU_VALUE.MENU,
    })
  }

  const onAnalyticsDashboardClick = () => {
    Mixpanel.track('AnalyticsMainClick', { source: 'dashboard' })

    window.localStorage.setItem(IsNotViewableDashboardsFeature, 'false')
  }

  const onAnalyticsReportsClick = () => {
    Mixpanel.track('ReportsMainClick')

    window.localStorage.setItem(IsNotViewableReportFeature, 'false')
  }

  const onImportVKClick = () => {
    Mixpanel.track('ImportVKMainClick')

    window.localStorage.setItem(IsNotViewableImportVKFeature, 'false')
  }

  const onTrackerClick = () => {
    Mixpanel.track('TrackerMainClick')

    window.localStorage.setItem(IsNotViewableTrackerFeature, 'false')
  }

  const onAccountsClick = () => {
    window.localStorage.setItem(IsNotViewableTelegramFeature, 'false')
    window.localStorage.setItem(IsNotViewableVKAdsFeature, 'false')
  }

  const onCampaignEditorClick = () => {
    Mixpanel.track('CampaignEditorMainClick')
    Mixpanel.track('MassEditorTelegramMainClick')

    window.localStorage.setItem(IsNotViewableCampaignEditorTelegramFeature, 'false')
    setIsCampaignEditorNewFeature(false)
  }

  const onAdsMarkingClick = () => {
    Mixpanel.track('MarkingMainClick')

    window.localStorage.setItem(IsNotViewableAdsMarkingFeature, 'false')
    setIsAdsMarkingNewFeature(false)
  }

  const onFinancialTermsClick = () => {
    Mixpanel.track('FinancialTermsClick')

    window.localStorage.setItem(IsNotViewableFinancialTermsLink, 'false')
    setIsFinancialTermsLink(false)
  }

  const onBidManagerClick = () => {
    Mixpanel.track('BidManagerMainClick')
  }

  const onFreeServicesClick = () => {
    window.localStorage.setItem(IsNotViewableFreeServicesFeature, 'false')
    setIsFreeServicesNewFeature(false)
  }

  const isSEProfile = profileType === SuggestCompanyTypes.SE

  return (
    <div className={classes.wrapper}>
      <div className={classes.menuWrapper}>
        {/* ------------- ACCOUNTS ------------- */}
        <NavLink to={Routes.ACCOUNTS} exact={false} className={classes.navLink} onClick={onAccountsClick}>
          <SectionIcon>
            <HomeIcon />
          </SectionIcon>
          <FormattedMessage id="menu.accounts" />
          {(isNotViewableTelegramFeature || isNotViewableVKAdsFeature) && <NewFeatureFlag isOpened={isMenuOpened} />}
        </NavLink>

        {/* ------------- ANALYTICS ------------- */}
        <Section opened={isAnalyticsMenuOpened} onClick={onAnalyticsMenuToggle} isActive={isAnalyticsMenuActive()}>
          <SectionInfo>
            <SectionIcon>
              <SectionEllipsedIcon>
                <ChartPieIcon />
              </SectionEllipsedIcon>
            </SectionIcon>
            <FormattedMessage id="menu.analytics" />
            {(isNotViewableDashboardsFeature || isNotViewableTrackerFeature || isNotViewableReportFeature) && (
              <NewFeatureFlag isOpened={isMenuOpened && !isAnalyticsMenuOpened} />
            )}
          </SectionInfo>
          {isAnalyticsMenuOpened && (
            <SectionArrow>
              <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
            </SectionArrow>
          )}
        </Section>
        <CSSTransition timeout={TRANSITION_TIMEOUT} in={isAnalyticsMenuOpened} classNames="opacity" unmountOnExit>
          <SubSection>
            <SubSectionLink to={Routes.ANALYTICS} exact={false} onClick={onAnalyticsDashboardClick}>
              <SectionIcon>
                <SectionSquaredIcon>
                  <DashboardIcon />
                </SectionSquaredIcon>
              </SectionIcon>
              <FormattedMessage id="menu.dashboards" />
              {isNotViewableDashboardsFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
            </SubSectionLink>
            <SubSectionLink to={Routes.REPORTS} exact={false} onClick={onAnalyticsReportsClick} data-tour-id="reports">
              <SectionIcon>
                <SectionSquaredIcon>
                  <ReportIcon />
                </SectionSquaredIcon>
              </SectionIcon>
              <FormattedMessage id="menu.reports" />
              {isNotViewableReportFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
            </SubSectionLink>
            <SubSectionLink to={Routes.TRACKER} exact={false} onClick={onTrackerClick}>
              <SectionIcon>
                <SectionSquaredIcon>
                  <TrackerIcon />
                </SectionSquaredIcon>
              </SectionIcon>
              <FormattedMessage id="menu.tracker" />
              {isNotViewableTrackerFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
            </SubSectionLink>
          </SubSection>
        </CSSTransition>

        {/* ------------- FINANCE -------------  */}
        <CSSTransition
          timeout={TRANSITION_TIMEOUT}
          in={isAnalyticsMenuOpened}
          classNames="analytics-menu"
          unmountOnExit={false}
        >
          <SectionBlock>
            {(!isProfileClient || !isSEProfile) && (
              <>
                <Section opened={isFinanceMenuOpened} onClick={onFinanceMenuToggle} isActive={isFinanceMenuActive()}>
                  <SectionInfo>
                    <SectionIcon>
                      <SectionEllipsedIcon>
                        <CoinsIcon />
                      </SectionEllipsedIcon>
                    </SectionIcon>
                    <FormattedMessage id="menu.finance" />
                    {isNotViewableFinancialTermsLink && (
                      <NewFeatureFlag isOpened={isMenuOpened && !isFinanceMenuOpened} />
                    )}
                  </SectionInfo>
                  {isFinanceMenuOpened && (
                    <SectionArrow>
                      <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
                    </SectionArrow>
                  )}
                </Section>
                <CSSTransition timeout={TRANSITION_TIMEOUT} in={isFinanceMenuOpened} classNames="opacity" unmountOnExit>
                  <SubSection>
                    {!isSEProfile && (
                      <SubSectionLink to={Routes.INVOICES} exact={false}>
                        <SectionIcon>
                          <SectionSquaredIcon>
                            <BillIcon />
                          </SectionSquaredIcon>
                        </SectionIcon>
                        <FormattedMessage id="menu.bill" />
                      </SubSectionLink>
                    )}
                    {!isProfileClient && (
                      <>
                        <SubSectionLink to={Routes.HISTORY} exact={false}>
                          <SectionIcon>
                            <SectionSquaredIcon>
                              <ClockFilledIcon />
                            </SectionSquaredIcon>
                          </SectionIcon>
                          <FormattedMessage id="menu.orderHistory" />
                        </SubSectionLink>
                        <SubSectionLink to={Routes.BUDGET} exact={false} onClick={onBudgetClick}>
                          <SectionIcon>
                            <SectionSquaredIcon>
                              <CycleIcon />
                            </SectionSquaredIcon>
                          </SectionIcon>
                          <FormattedMessage id="menu.distributeBudget" />
                        </SubSectionLink>
                        <SubSectionExternalLink
                          href={FINANCIAL_TERMS_URL}
                          target="_blank"
                          onClick={onFinancialTermsClick}
                        >
                          <SectionIcon>
                            <SectionSquaredIcon>
                              <PercentFilledIcon />
                            </SectionSquaredIcon>
                          </SectionIcon>
                          <FormattedMessage id="menu.financialTerms" />
                          {isFinancialTermsLink && <NewFeatureFlag isOpened={isMenuOpened && isFinanceMenuOpened} />}
                        </SubSectionExternalLink>
                      </>
                    )}
                  </SubSection>
                </CSSTransition>
              </>
            )}

            {/* ------------- TOOLS ------------- */}
            <CSSTransition
              timeout={TRANSITION_TIMEOUT}
              in={isFinanceMenuOpened}
              classNames={!isSEProfile ? (isProfileClient ? 'finance-menu-client' : 'finance-menu') : 'finance-menu-se'}
              unmountOnExit={false}
            >
              <SectionBlock>
                {(!isProfileClient || isViewAdsMarking) && (
                  <>
                    <Section
                      data-testid="menu-tools-btn"
                      opened={isToolMenuOpened}
                      onClick={onToolMenuToggle}
                      isActive={isToolMenuActive()}
                    >
                      <SectionInfo>
                        <SectionIcon>
                          <SectionEllipsedIcon>
                            <RocketIcon />
                          </SectionEllipsedIcon>
                        </SectionIcon>
                        <FormattedMessage id="menu.tools" />
                        {(isNotViewableImportVKFeature || isCampaignEditorNewFeature) && (
                          <NewFeatureFlag isOpened={isMenuOpened && !isToolMenuOpened} />
                        )}
                      </SectionInfo>
                      {isToolMenuOpened && (
                        <SectionArrow>
                          <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
                        </SectionArrow>
                      )}
                    </Section>
                    <CSSTransition
                      timeout={TRANSITION_TIMEOUT}
                      in={isToolMenuOpened}
                      classNames="opacity"
                      unmountOnExit
                    >
                      <SubSection>
                        {!isProfileClient && (
                          <SubSectionLink to={Routes.FREE_SERVICES} exact={false} onClick={onFreeServicesClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <FreeServicesIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.freeServices" />
                            {isFreeServicesNewFeature && <NewFeatureFlag isOpened={isMenuOpened && isToolMenuOpened} />}
                          </SubSectionLink>
                        )}
                        {isViewAdsMarking && (
                          <SubSectionLink to={Routes.ADS_MARKING} exact={false} onClick={onAdsMarkingClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <AdsMarkingIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.adsMarking" />
                            {isAdsMarkingNewFeature && <NewFeatureFlag isOpened={isMenuOpened && isToolMenuOpened} />}
                          </SubSectionLink>
                        )}
                        {!isProfileClient && (
                          <SubSectionLink
                            data-testid="menu-tools-campaign-editor-btn"
                            to={Routes.CAMPAIGN_EDITOR}
                            exact={false}
                            onClick={onCampaignEditorClick}
                          >
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <CampaignEditorIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.campaignEditor" />
                            {isCampaignEditorNewFeature && (
                              <NewFeatureFlag isOpened={isMenuOpened && isToolMenuOpened} />
                            )}
                          </SubSectionLink>
                        )}
                        {!isProfileClient && (
                          <SubSectionExternalLink href={BID_MANAGER_URL} target="_blank" onClick={onBidManagerClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <BidManagerIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.bidManager" />
                            &nbsp;
                            <span className={classes.menuLabel}>
                              (<FormattedMessage id="menu.bidManagerLabel" />)
                            </span>
                          </SubSectionExternalLink>
                        )}
                        {!isProfileClient && (
                          <SubSectionLink to={Routes.IMPORT_VK} exact={false} onClick={onImportVKClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <VKShopIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.importVK" />
                            {isNotViewableImportVKFeature && (
                              <NewFeatureFlag isOpened={isMenuOpened && isToolMenuOpened} />
                            )}
                          </SubSectionLink>
                        )}
                      </SubSection>
                    </CSSTransition>
                  </>
                )}

                {/* ------------- DOCUMENTS ------------- */}
                <CSSTransition
                  timeout={TRANSITION_TIMEOUT}
                  in={isToolMenuOpened}
                  classNames={isViewAdsMarking && isProfileClient ? 'tool-menu-with-one-item' : 'tool-menu'}
                  unmountOnExit={false}
                >
                  <SectionBlock>
                    <NavLink to={Routes.ACCOUNTING} exact={false} className={classes.navLink}>
                      <SectionIcon>
                        <DocIcon />
                      </SectionIcon>
                      <FormattedMessage id="menu.documents" />
                    </NavLink>
                  </SectionBlock>
                </CSSTransition>

                {/* ------------- EDUCATION ------------- */}
                <CSSTransition
                  timeout={TRANSITION_TIMEOUT}
                  in={isToolMenuOpened}
                  classNames={isViewAdsMarking && isProfileClient ? 'tool-menu-with-one-item' : 'tool-menu'}
                  unmountOnExit={false}
                >
                  <SectionBlock>
                    <>
                      <Section
                        opened={isEducationMenuOpened}
                        onClick={onEducationMenuToggle}
                        isActive={isEducationMenuActive()}
                      >
                        <SectionInfo>
                          <SectionIcon>
                            <SectionEllipsedIcon>
                              <CapIcon style={{ width: 24, height: 24 }} />
                            </SectionEllipsedIcon>
                          </SectionIcon>
                          <FormattedMessage id="menu.education" />
                          {isNotViewableEducationMaterialsFeature && (
                            <NewFeatureFlag isOpened={isMenuOpened && !isEducationMenuOpened} />
                          )}
                        </SectionInfo>
                        {isEducationMenuOpened && (
                          <SectionArrow>
                            <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
                          </SectionArrow>
                        )}
                      </Section>
                      <CSSTransition
                        timeout={TRANSITION_TIMEOUT}
                        in={isEducationMenuOpened}
                        classNames="opacity"
                        unmountOnExit
                      >
                        <SubSection>
                          <SubSectionLink to={Routes.EDUCATION} exact={false} onClick={onEducationMaterialsClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <SocialBigLogoIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <div
                              className={cn({
                                [classes.ellipsisMenuItem]: isNotViewableEducationMaterialsFeature,
                              })}
                            >
                              <FormattedMessage id="menu.educationMaterials" />
                            </div>
                            {isNotViewableEducationMaterialsFeature && (
                              <NewFeatureFlag isOpened={isMenuOpened && isEducationMenuOpened} />
                            )}
                          </SubSectionLink>
                          <SubSectionExternalLink href={LANDING_HELP_URL} target="_blank" onClick={onSupportClick}>
                            <SectionIcon>
                              <SectionSquaredIcon>
                                <QuestionFilledIcon />
                              </SectionSquaredIcon>
                            </SectionIcon>
                            <FormattedMessage id="menu.customerAssistance" />
                          </SubSectionExternalLink>
                        </SubSection>
                      </CSSTransition>
                    </>
                  </SectionBlock>
                </CSSTransition>

                {/* ------------- NEW MENU ITEM ------------- */}
                {/* <CSSTransition
                  timeout={TRANSITION_TIMEOUT}
                  in={isEducationMenuOpened}
                  classNames="education-menu"
                  unmountOnExit={false}
                >
                  <SectionBlock></SectionBlock>
                </CSSTransition> */}
              </SectionBlock>
            </CSSTransition>
          </SectionBlock>
        </CSSTransition>
      </div>
      <CSSTransition
        timeout={TRANSITION_TIMEOUT}
        in={isMenuOpened}
        classNames={isGreaterThanDesktop ? 'referral' : ''}
        unmountOnExit={false}
      >
        <div className={cn(classes.referralWrapper, { [classes.referralWrapperClosed]: !isMenuOpened })}>
          <NavLink
            to={Routes.REFERRAL}
            exact={false}
            className={cn(classes.navLink, classes.isDashed, classes.referralLink)}
          >
            <SectionIcon>
              <InviteFriendIcon />
            </SectionIcon>
            <FormattedMessage id="menu.inviteFriend" />
            {isNotViewableReferral && <NewFeatureFlag isOpened={isMenuOpened} />}
          </NavLink>
          <div className={classes.referralButton}>
            <ReferralButton />
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default SubMenu
