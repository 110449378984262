import React from 'react'

import { SlateGreyLighten34, White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White, fillBg = SlateGreyLighten34 } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill={fillBg} />
      <path
        d="M7.00748 14.2887C7.36854 13.9338 7.37353 13.3534 7.01864 12.9923C6.66375 12.6313 6.08336 12.6263 5.7223 12.9812L7.00748 14.2887ZM6.36395 13.6358L5.71576 12.9877C5.35886 13.3446 5.35761 13.9228 5.71297 14.2812C6.06833 14.6397 6.64658 14.6434 7.00654 14.2896L6.36395 13.6358ZM14.2847 7.01148C14.6427 6.65349 14.6427 6.07307 14.2847 5.71509C13.9267 5.3571 13.3463 5.3571 12.9883 5.71509L14.2847 7.01148ZM7.01317 5.71517C6.65522 5.35713 6.07481 5.35706 5.71678 5.715C5.35874 6.07294 5.35867 6.65336 5.71661 7.01139L7.01317 5.71517ZM12.9883 14.284C13.3463 14.642 13.9267 14.642 14.2847 14.284C14.6427 13.9261 14.6427 13.3456 14.2847 12.9877L12.9883 14.284ZM5.7223 12.9812L5.72136 12.9821L7.00654 14.2896L7.00748 14.2887L5.7223 12.9812ZM7.01215 14.284L10.6484 10.6478L9.35204 9.35137L5.71576 12.9877L7.01215 14.284ZM10.6484 10.6478L14.2847 7.01148L12.9883 5.71509L9.35204 9.35137L10.6484 10.6478ZM10.6485 9.35145L7.01317 5.71517L5.71661 7.01139L9.35196 10.6477L10.6485 9.35145ZM9.35204 10.6478L12.9883 14.284L14.2847 12.9877L10.6484 9.35137L9.35204 10.6478Z"
        fill={fill}
      />
    </svg>
  )
}
