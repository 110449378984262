import React from 'react'
import { DefaultTheme } from 'react-jss'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import DotsLoader from '../DotsLoader'
import useStyles from './styles'
import { BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT, IButtonSMBProps } from './types'

const Button = (props: IButtonSMBProps & { theme?: DefaultTheme }) => {
  const {
    children,
    label,
    iconLeft,
    size = BUTTON_SIZE.size32,
    variant = BUTTON_VARIANT.primary,
    type = BUTTON_TYPE.button,
    disabled = false,
    fullWidth,
    className,
    fillIconType,
    minWidth,
    isLoading,
    theme,
    animationClassName,
    ...other
  } = props

  const classes = useStyles({ fillIconType, minWidth, isLoading, theme })

  const buttonProps: any = {}
  const hasAnimation = !!animationClassName

  let Component: string | typeof Link = 'button'

  if (other.href) {
    Component = 'a'
    buttonProps.rel = 'noopener noreferrer'
    buttonProps.target = '_blank'
  }
  if (other.to) {
    Component = Link
  }

  if (Component !== 'button') {
    buttonProps.role = 'button'
  } else {
    buttonProps.type = type ? type : 'button'
    buttonProps.disabled = disabled
  }

  return (
    <Component
      className={cn(
        classes.root,
        classes[size],
        classes[variant],
        classes[type],
        fullWidth && classes.fullWidth,
        !!other.to && disabled && classes.disabledLink,
        className
      )}
      {...buttonProps}
      {...other}
      onClick={disabled ? undefined : other.onClick}
    >
      {iconLeft ?? label ? (
        <div className={cn(classes.content, { [classes.zIndexContent]: hasAnimation })}>
          {iconLeft && <span className={classes.iconWrapper}>{iconLeft}</span>}
          {label && (
            <span className={classes.label}>
              {isLoading ? (
                <div className={classes.loaderWrapper}>
                  <DotsLoader height={20} width={60} />
                </div>
              ) : (
                label
              )}
            </span>
          )}
        </div>
      ) : isLoading ? (
        <div className={classes.loaderWrapper}>
          <DotsLoader height={20} width={60} />
        </div>
      ) : (
        children
      )}
      {hasAnimation && <div className={animationClassName} />}
    </Component>
  )
}

export { Button }
