import { createUseStyles } from 'react-jss'

import {
  BackgroundGrey,
  PurpleBase,
  scrollbarClr,
  SlateGreyLighten22,
  SlateGreyLighten43,
  TextGreyBase,
  TextSlateGreyLighten16,
  White,
} from '@/const/colors'
import { baseFontFamily, baseTransDur, smallFont } from '@/const/commonStyles'

export default createUseStyles(
  {
    navLink: {
      position: 'relative',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.2px',
      font: `500 ${smallFont} ${baseFontFamily}`,
      textDecoration: 'none',
      color: TextGreyBase,
      whiteSpace: 'nowrap',

      '& svg path, & svg rect': {
        fill: SlateGreyLighten22,
      },

      '&.active': {
        color: PurpleBase,

        '& svg path, & svg rect': {
          fill: PurpleBase,
        },
      },

      '&$isDashed': {
        borderTop: `1px dashed ${SlateGreyLighten43}`,
      },

      '&:hover': {
        backgroundColor: BackgroundGrey,
        transitionDuration: baseTransDur,
      },
    },
    isDashed: {},
    menuLabel: {
      color: TextSlateGreyLighten16,
    },
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    referralWrapper: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: White,
      zIndex: 2,
      paddingBottom: 20,
    },
    referralWrapperClosed: {
      paddingBottom: 22,
    },
    referralLink: {
      flexShrink: 0,
    },
    referralButton: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 14px',
    },
    menuWrapper: {
      overflow: 'hidden auto',

      '&::-webkit-scrollbar': {
        width: 5,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 17,
        background: scrollbarClr,
      },
    },
    ellipsisMenuItem: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 140,
    },
  },
  { name: 'SubMenu' }
)
