import { createUseStyles } from 'react-jss'

import { GreenBase, TextGreyBase, TextSlateGreyLighten8, White } from '@/const/colors'
import { baseTransDur } from '@/const/commonStyles'
import { FontButtonSmall, FontInteractive, FontSmall } from '@/const/fontStyles'

export default createUseStyles(
  {
    root: {
      position: 'relative',
    },
    button: {
      ...FontButtonSmall,
      cursor: 'pointer',
      borderRadius: 4,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px 16px',
      background: 'rgba(255, 255, 255, 0.1)',
      border: 'none',
      color: White,

      '&:hover:not(:disabled)': {
        background: 'rgba(255, 255, 255, 0.14)',
        color: White,
        transitionDuration: baseTransDur,
      },

      '&:focus:not(:disabled)': {
        background: 'rgba(255, 255, 255, 0.18)',
        color: White,
      },

      '&:hover:not(:disabled), &:focus:not(:disabled)': {
        transform: 'none',
      },
    },
    icon: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    textWrapper: {
      marginRight: 4,
    },
    arrowWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    newLabel: {
      padding: '0 3px 0 5px',
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      color: White,
      backgroundColor: GreenBase,
      borderRadius: 4,
      userSelect: 'none',
      marginLeft: 4,
    },
    popover: {
      right: 0,
      width: 240,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: White,
    },
    link: {
      textDecoration: 'none',
    },
    popoverButton: {
      display: 'flex',
      flexDirection: 'row',
      padding: '8px 12px',
    },
    iconWrapper: {
      marginRight: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    popoverTextWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: TextGreyBase,
      ...FontInteractive,
      marginBottom: 2,
    },
    description: {
      color: TextSlateGreyLighten8,
      ...FontSmall,
    },
  },
  { name: 'Education' }
)
