import React from 'react'

import { RedBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = RedBase, fillBg = RedBase } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_719_35572)">
        <path
          d="M9.00034 4.50002C9.22578 4.50002 9.44198 4.58957 9.60138 4.74898C9.76079 4.90839 9.85034 5.12459 9.85034 5.35002V9.4729C9.85034 9.69834 9.76079 9.91454 9.60138 10.0739C9.44198 10.2333 9.22578 10.3229 9.00034 10.3229C8.77491 10.3229 8.55871 10.2333 8.3993 10.0739C8.2399 9.91454 8.15034 9.69834 8.15034 9.4729V5.35002C8.15034 5.12459 8.2399 4.90839 8.3993 4.74898C8.55871 4.58957 8.77491 4.50002 9.00034 4.50002Z"
          fill={fill}
        />
        <path
          d="M8.99995 13.5C9.26517 13.5 9.51952 13.3947 9.70706 13.2071C9.8946 13.0196 9.99995 12.7652 9.99995 12.5C9.99995 12.2348 9.8946 11.9805 9.70706 11.7929C9.51952 11.6054 9.26517 11.5 8.99995 11.5C8.73474 11.5 8.48038 11.6054 8.29285 11.7929C8.10531 11.9805 7.99995 12.2348 7.99995 12.5C7.99995 12.7652 8.10531 13.0196 8.29285 13.2071C8.48038 13.3947 8.73474 13.5 8.99995 13.5Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.9C5.07878 1.9 1.9 5.07878 1.9 9C1.9 12.9212 5.07878 16.1 9 16.1C12.9212 16.1 16.1 12.9212 16.1 9C16.1 5.07878 12.9212 1.9 9 1.9ZM0.599998 9C0.599998 4.36081 4.36081 0.599998 9 0.599998C13.6392 0.599998 17.4 4.36081 17.4 9C17.4 13.6392 13.6392 17.4 9 17.4C4.36081 17.4 0.599998 13.6392 0.599998 9Z"
          fill={fillBg}
        />
      </g>
      <defs>
        <clipPath id="clip0_719_35572">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
