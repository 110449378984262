import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.982 11.6785C10.1275 11.9436 9.39951 11.7971 9.01767 11.6785L4.67461 9.6383L4.24994 14.8464C4.24994 15.9163 6.82428 17.4752 9.99996 17.4752C13.1757 17.4752 15.75 15.9163 15.75 14.8464L15.3253 9.63799L10.982 11.6785Z"
        fill={fill}
      />
      <path
        d="M19.0544 6.08685L10.7007 2.80122C10.2455 2.65988 9.75439 2.65988 9.29948 2.80122L0.94548 6.08685C0.240504 6.30567 0.240504 7.24786 0.94548 7.46669L2.33095 8.01166C2.05339 8.39258 1.88436 8.84571 1.86638 9.33836C1.57798 9.50543 1.37493 9.80688 1.37493 10.1667C1.37493 10.493 1.54504 10.7675 1.79001 10.9431L1.02544 14.4201C0.958957 14.7224 1.18656 15.0094 1.49293 15.0094H3.17331C3.47998 15.0094 3.70759 14.7224 3.6411 14.4201L2.87653 10.9431C3.1215 10.7675 3.29161 10.493 3.29161 10.1667C3.29161 9.81656 3.09784 9.52359 2.82262 9.35349C2.84159 8.97462 3.0045 8.63233 3.27062 8.38128L9.29918 10.7526C9.57051 10.8368 10.091 10.9418 10.7004 10.7526L19.0544 7.46699C19.7597 7.24786 19.7597 6.30597 19.0544 6.08685Z"
        fill={fill}
      />
    </svg>
  )
}
