import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactourStep } from 'reactour'

import useStyles from './styles'

import { Button, BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT } from '@/components/ButtonSMB'

export const getProductTourSteps = (onClick: () => void): ReactourStep[] => {
  const classes = useStyles()

  return [
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="header.productTour.title" />
          </h4>
          <p>
            <FormattedMessage id="header.productTour.text" />
          </p>
          <div className={classes.productTourButton}>
            <Button
              size={BUTTON_SIZE.size40}
              variant={BUTTON_VARIANT.green}
              type={BUTTON_TYPE.button}
              onClick={onClick}
            >
              <FormattedMessage id="header.productTour.button" />
            </Button>
          </div>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="header-education-first-link"]',
      style: { top: '24px' },
    },
  ]
}
