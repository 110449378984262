import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#C8B3FF' } = props
  const defaultStyle = { width: 17, height: 17 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.56974 1.35872C7.90266 0.514572 9.09734 0.51457 9.43026 1.35872L11.0277 5.40897C11.1293 5.66669 11.3333 5.8707 11.591 5.97234L15.6413 7.56974C16.4854 7.90266 16.4854 9.09734 15.6413 9.43026L11.591 11.0277C11.3333 11.1293 11.1293 11.3333 11.0277 11.591L9.43026 15.6413C9.09734 16.4854 7.90266 16.4854 7.56974 15.6413L5.97234 11.591C5.8707 11.3333 5.66669 11.1293 5.40897 11.0277L1.35872 9.43026C0.514572 9.09734 0.51457 7.90266 1.35872 7.56974L5.40897 5.97234C5.66669 5.8707 5.8707 5.66669 5.97234 5.40897L7.56974 1.35872Z"
        fill={fill}
      />
    </svg>
  )
}
