import { createUseStyles } from 'react-jss'

import { TextGreyBase } from '@/const/colors'
import { FontBody1, FontBody1Accent, FontBody2, FontH4 } from '@/const/fontStyles'

export default createUseStyles(
  {
    description: {
      ...FontBody1Accent,
      margin: '0 0 16px',
      color: TextGreyBase,
    },
    list: {
      marginBottom: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,

      '& span': {
        ...FontBody2,
        color: TextGreyBase,
      },

      '& svg': {
        flexShrink: 0,
      },
    },
    text: {
      ...FontBody1Accent,
      color: TextGreyBase,
      margin: '0 0 24px',
    },
    withdrawTitle: {
      ...FontH4,
      color: TextGreyBase,
      margin: '0 0 24px',
      maxWidth: 380,
    },
    withdrawDescription: {
      ...FontBody1,
      margin: '0 0 16px',
      color: TextGreyBase,
    },
  },
  { name: 'ReferralUtils' }
)
