import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { customAxios } from '../../utils'

import { API_URL } from '@/const'
import { AdAccountsActionTypes, IAccountBody, IAdAccountsState } from '@/store/adaccounts/types'
import { ThunkResult } from '@/store/store'
import { Platforms } from '@/types'

export enum AddVKActionTypes {
  SET_ADD_VK_LOADING = 'SET_ADD_VK_LOADING',
  SET_ADD_VK_ERROR = 'SET_ADD_VK_ERROR',
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddVKActionTypes.SET_ADD_VK_LOADING, data: isLoading }
}

export function createVKAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/vk_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: Platforms.VK })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddVKActionTypes.SET_ADD_VK_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddVKActionTypes.SET_ADD_VK_LOADING,
          data: false,
        })
      })
  }
}

export function createClientVKAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/vk_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => response)
      .catch((error: AxiosError) => {
        dispatch({
          type: AddVKActionTypes.SET_ADD_VK_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddVKActionTypes.SET_ADD_VK_LOADING,
          data: false,
        })
      })
  }
}

export function setError(error: any): AnyAction {
  return { type: AddVKActionTypes.SET_ADD_VK_ERROR, data: error }
}
