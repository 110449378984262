import { AxiosError, AxiosResponse } from 'axios'

import { getProfileAdmarkInfo } from '../../actions'
import { getCounterparties } from '../actions'
import { CounterpartiesActionTypes, ICounterpartiesListType } from '../types'

import { closeModal, openModal } from '@/common/actions'
import { API_URL } from '@/const'
import { ThunkResult } from '@/store/store'
import { customAxios } from '@/utils'

export const reducerName = 'getAdsMarkingCounterparties.modals'

export const openAdsMarkingCounterpartiesModal = openModal(reducerName)
export const closeAdsMarkingCounterpartiesModal = closeModal(reducerName)

export function deleteCounterparty(id: number): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    dispatch({ type: CounterpartiesActionTypes.SET_COUNTERPARTIES_LOADING })
    dispatch({ type: CounterpartiesActionTypes.CLEAR_COUNTERPARTIES })
    return customAxios(`${API_URL}/ad_mark/contractors/${id}/`, 'DELETE', null, true)
      .then((response: AxiosResponse<ICounterpartiesListType>) => {
        dispatch(getCounterparties())
        dispatch(getProfileAdmarkInfo())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateCounterparty(id: string, body: any): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    dispatch({ type: CounterpartiesActionTypes.SET_COUNTERPARTIES_LOADING })
    dispatch({ type: CounterpartiesActionTypes.CLEAR_COUNTERPARTIES })
    return customAxios(`${API_URL}/ad_mark/contractors/${id}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<ICounterpartiesListType>) => {
        dispatch(getCounterparties())
        dispatch(getProfileAdmarkInfo())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
