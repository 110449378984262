import React from 'react'

import { AdAccountExtraTypes, AdAccountTypes, IAdAccount } from '@/store/adaccounts/types'
import { IOnboardingObject } from '@/store/onboarding/types'
import { IUserProfile, OnboardingTypes } from '@/store/user/types'
import { GroupingTabValues, HINT_STATUS } from '@/types'

export interface IAccountsState {
  isPageLoading: boolean
  isShowedAutopaymentModal: boolean
  isOnboardingProductTourOpen: boolean
  isOnboardingShown: boolean
  selectedAccount: null | IAdAccount
}

export type LoadingTypes = 'isPageLoading' | 'isMoreLoading' | 'isSearching' | 'isAccountsLoading' | 'isSectionLoading'

export interface IHistoryModalProps {
  onButtonClick: () => void
  email?: string
}

export enum OnboardingStatuses {
  START = 'start',
  MIDDLE = 'middle',
  FINISH = 'finish',
}

export interface IOnboardingPageState {
  stepsCount: number
  activeStep: number
}

interface IOnboardingPropsFromDispatch {
  updateProfile: (data: { onboarding: OnboardingTypes }) => Promise<any>
}

interface IOnboardingPropsFromState {
  isDesktop: boolean
  onboardingStatus: OnboardingTypes
  profile: IUserProfile
}

export type IOnboardingProps = IOnboardingPropsFromState & IOnboardingPropsFromDispatch

export interface IConditionsProps {
  network: AdAccountExtraTypes
  isAgency: boolean
  onButtonClick: (network: AdAccountTypes) => (event: React.MouseEvent) => void
  isDisabled: boolean
  isAgencyClient: boolean
}

export interface IFacebookTableProps {
  isAgency: boolean
}

export interface IEmptyAccountProps {
  account: IAdAccount
}

export interface IOnboardingHintProps {
  onboarding: IOnboardingObject[]
  onClose: () => void
  onUpdate: (setupHint: string, hintStatus: HINT_STATUS, title: string) => void
  account: IAdAccount
  onClickLink: (title: string) => (event: React.MouseEvent) => void
}

export type GroupingTypes = GroupingTabValues
