export enum ReferralActionTypes {
  SET_REFERRAL_PROMO_CODE = 'SET_REFERRAL_PROMO_CODE',
  SET_REFERRAL_STATISTICS = 'SET_REFERRAL_STATISTICS',
  SET_REFERRAL_STATISTICS_LOADING = 'SET_REFERRAL_STATISTICS_LOADING',
  SET_REFERRAL_BONUSES = 'SET_REFERRAL_BONUSES',
  SET_REFERRAL_BONUSES_LOADING = 'SET_REFERRAL_BONUSES_LOADING',
  SET_REFERRAL_DETAILS = 'SET_REFERRAL_DETAILS',
  SET_REFERRAL_DETAILS_LOADING = 'SET_REFERRAL_DETAILS_LOADING',
}

export enum Modals {
  WITHDRAW_BONUS = 'withdrawBonus',
  SUCCESS_MESSAGE = 'successMessage',
  LEGAL_MESSAGE = 'legalMessage',
}

export const reducerModalName = 'referral.modals'

export interface IReferralPromo {
  code: string
}

export interface IReferralStatistics {
  data: IReferralStatisticsData
  isLoading: boolean
}

export interface IReferralStatisticsData {
  paid_bonuses: string
  paid_profiles: number
  spending_profiles: number
  all_activated: number
}

export interface IReferralBonuses {
  data: IReferralBonusesData
  isLoading: boolean
}

export interface IReferralBonusesData {
  accrued_bonuses: string
  is_ready_to_withdraw: boolean
  processed_bonuses: string
  counted_referrals: number
}

export interface IReferral {
  promo: IReferralPromo
  statistics: IReferralStatistics
  bonuses: IReferralBonuses
  modals: IModal | null
  details: IReferralDetails
}

export interface IModal {
  name: Modals
  data?: any
}

export interface IReferralDetails {
  data: IReferralDetailsBody
  isLoading: boolean
}

export interface IReferralDetailsBody {
  details: string
}

export interface IReferralPromoCode {
  code: string
}
