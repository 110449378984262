import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ConnectedRouter } from 'connected-react-router'

import AppModals from '../../../Modals'
import { PrivateRoute } from '../../../PrivateRoute'
import { StyledHeader } from '../../styled'
import ContentBlock from '../ContentBlock'
import ContentWrapper from '../ContentWrapper'
import Menu from '../Menu'

import Loader from '@/components/Loader'
import { RouteParams, Routes } from '@/const/routes'
import { shouldRenderAdsMarking } from '@/pages/AdsMarking/AdsMarkingRoot/selector'
import Index from '@/pages/Index'
import Logout from '@/pages/Logout'
import history from '@/store/history'
import IApplicationState from '@/types/state'

const Analytics = loadable(() => import('@/pages/Analytics/AnalyticsRoot'), {
  fallback: <Loader />,
})

const Education = loadable(() => import('@/pages/Education/EducationRoot'), {
  fallback: <Loader />,
})

const Reports = loadable(() => import('@/pages/Analytics/ReportsRoot'), {
  fallback: <Loader />,
})

const Report = loadable(() => import('@/pages/Analytics/ReportRoot'), {
  fallback: <Loader />,
})

const Tracker = loadable(() => import('@/pages/Analytics/TrackerRoot'), {
  fallback: <Loader />,
})

const AddClient = loadable(() => import('@/pages/AddClient/AddClientRoot'), {
  fallback: <Loader />,
})

const Accounts = loadable(() => import('@/pages/Accounts'), {
  fallback: <Loader />,
})

const Balance = loadable(() => import('@/pages/Balance'), {
  fallback: <Loader />,
})

const Profile = loadable(() => import('@/pages/Profile'), {
  fallback: <Loader />,
})

const Invoices = loadable(() => import('@/pages/Invoices'), {
  fallback: <Loader />,
})

const PasswordChange = loadable(() => import('@/pages/PasswordChange'), {
  fallback: <Loader />,
})

const Signin = loadable(() => import('@/pages/Signin'), {
  fallback: <Loader />,
})

const Signup = loadable(() => import('@/pages/Signup'), {
  fallback: <Loader />,
})

const History = loadable(() => import('@/pages/History'), {
  fallback: <Loader />,
})

const Accounting = loadable(() => import('@/pages/Accounting'), {
  fallback: <Loader />,
})

const ErrorPage = loadable(() => import('@/pages/ErrorPage'), {
  fallback: <Loader />,
})

const ForgotPasswordRequest = loadable(() => import('@/pages/ForgotPasswordRequest'), {
  fallback: <Loader />,
})

const ForgotPasswordConfirm = loadable(() => import('@/pages/ForgotPasswordConfirm'), {
  fallback: <Loader />,
})

const AddAccounts = loadable(() => import('@/pages/AddAccounts'), {
  fallback: <Loader />,
})

const Budget = loadable(() => import('@/pages/Budget'), {
  fallback: <Loader />,
})

const Settings = loadable(() => import('@/pages/Settings'), {
  fallback: <Loader />,
})

const Optimizer = loadable(() => import('@/pages/Optimizer'), {
  fallback: <Loader />,
})

// const FeedManager = loadable(() => import('@/pages/FeedManager'), {
//   fallback: <Loader />,
// })

// const FeedManagerAbout = loadable(() => import('@/pages/FeedManager/components/About'), {
//   fallback: <Loader />,
// })

// const FeedManagerWelcome = loadable(() => import('@/pages/FeedManager/components/Welcome'), {
//   fallback: <Loader />,
// })

// const FeedManagerCreate = loadable(() => import('@/pages/FeedManager/components/Create'), {
//   fallback: <Loader />,
// })

const ShareAudiences = loadable(() => import('@/pages/ShareAudiences'), {
  fallback: <Loader />,
})

const Audiences = loadable(() => import('@/pages/Audiences'), {
  fallback: <Loader />,
})

const AudiencesWelcome = loadable(() => import('@/pages/Audiences/components/Welcome'), {
  fallback: <Loader />,
})

const AudiencesQuiz = loadable(() => import('@/pages/Audiences/components/Quiz'), {
  fallback: <Loader />,
})

const Notifications = loadable(() => import('@/pages/Notifications'), {
  fallback: <Loader />,
})

const Referral = loadable(() => import('@/pages/Referral'), {
  fallback: <Loader />,
})

const MyCashback = loadable(() => import('@/pages/MyCashback'), {
  fallback: <Loader />,
})

const AntiBan = loadable(() => import('@/pages/AntiBan'), {
  fallback: <Loader />,
})

const AntiBanWelcome = loadable(() => import('@/pages/AntiBan/components/Welcome'), {
  fallback: <Loader />,
})

const AntiBanBlockingActions = loadable(() => import('@/pages/AntiBan/components/BlockingActions'), {
  fallback: <Loader />,
})

const AdsMarking = loadable(() => import('../../../../pages/AdsMarking'), {
  fallback: <Loader />,
})

const AdsMarkingAddContract = loadable(() => import('../../../../pages/AdsMarking/AddContractRoot'), {
  fallback: <Loader />,
})

const AdsMarkingWelcome = loadable(() => import('../../../../pages/AdsMarkingWelcome'), {
  fallback: <Loader />,
})

const AntiBanIncreaseProtection = loadable(() => import('@/pages/AntiBan/components/IncreaseProtection'), {
  fallback: <Loader />,
})

const ImportVK = loadable(() => import('@/pages/ImportVK/ImportVKRoot'), {
  fallback: <Loader />,
})

const FreeServices = loadable(() => import('@/pages/FreeServices'), {
  fallback: <Loader />,
})

const CampaignEditor = loadable(() => import('@/pages/CampaignEditor/CampaignEditorRoot'), {
  fallback: <Loader />,
})

const CampaignReport = loadable(() => import('@/pages/CampaignEditor/CampaignReport'), {
  fallback: <Loader />,
})

const ImportVKVerify = loadable(() => import('@/pages/ImportVK/components/ImportVKVerify'), {
  fallback: <Loader />,
})

const ConfirmClient = loadable(() => import('@/pages/ConfirmClient'), {
  fallback: <Loader />,
})

const AddAvito = loadable(() => import('@/pages/AddAvito'), {
  fallback: <Loader />,
})

const AddTelegram = loadable(() => import('@/pages/AddTelegram'), {
  fallback: <Loader />,
})

const AddYandexPromo = loadable(() => import('@/pages/AddYandexPromo'), {
  fallback: <Loader />,
})

const AddVKAds = loadable(() => import('@/pages/AddVKAds'), {
  fallback: <Loader />,
})

const AddVKAdsMarket = loadable(() => import('@/pages/AddVKAdsMarket'), {
  fallback: <Loader />,
})

const AccessDenied = loadable(() => import('@/pages/AccessDenied'), {
  fallback: <Loader />,
})

const AddMyTarget = loadable(() => import('@/pages/AddMyTarget'), {
  fallback: <Loader />,
})

const AddVK = loadable(() => import('@/pages/AddVK'), {
  fallback: <Loader />,
})

const AddYandex = loadable(() => import('@/pages/AddYandex'), {
  fallback: <Loader />,
})

class ContentClass extends PureComponent<any, any> {
  constructor(props: any) {
    super(props)
  }

  public render() {
    const { isAuthorized, hasProfile, isViewAdsMarking } = this.props

    const isHeaderNeeded = history.location.pathname.includes(Routes.CONFIRM_CLIENT)

    const isSmallContainerPadding = history.location.pathname.includes(Routes.CAMPAIGN_EDITOR)

    return (
      <ConnectedRouter history={history}>
        <>
          {(isAuthorized || isHeaderNeeded) && <StyledHeader />}
          <ContentWrapper isAuthorized={isAuthorized || isHeaderNeeded}>
            {isAuthorized && hasProfile && <Menu />}
            <ContentBlock
              isSmallContainerPadding={isSmallContainerPadding}
              hasProfile={hasProfile}
              isAuthorized={isAuthorized}
              isWhite={isHeaderNeeded}
            >
              <Switch>
                <PrivateRoute exact={true} path={Routes.INDEX} component={Index} />
                <PrivateRoute exact={true} path={`${Routes.BALANCE}/${RouteParams.CLIENT_ID}`} component={Balance} />
                <PrivateRoute exact={true} path={Routes.PROFILE} component={Profile} />
                <PrivateRoute exact={true} path={Routes.CHANGE_PASSWORD} component={PasswordChange} />
                <PrivateRoute exact={true} path={Routes.INVOICES} component={Invoices} />
                {isViewAdsMarking && (
                  <PrivateRoute exact={true} path={Routes.ADS_MARKING} component={AdsMarkingWelcome} />
                )}
                {isViewAdsMarking && (
                  <PrivateRoute
                    exact={true}
                    path={`${Routes.ADS_MARKING}/${RouteParams.ADS_MARKING_TAB}`}
                    component={AdsMarking}
                  />
                )}
                {isViewAdsMarking && (
                  <PrivateRoute exact={true} path={Routes.ADD_CONTRACT} component={AdsMarkingAddContract} />
                )}

                <PrivateRoute exact={true} path={`${Routes.ACCOUNTING}/${RouteParams.TAB}`} component={Accounting} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ACCOUNTS}/${RouteParams.ACCOUNT_SECTION}/${RouteParams.ACCOUNT_TAB}`}
                  component={Accounts}
                />
                <PrivateRoute exact={true} path={Routes.ACCOUNTS} component={Accounts} />
                <PrivateRoute exact={true} path={Routes.ADD_CLIENT} component={AddClient} />
                <PrivateRoute exact={true} path={Routes.ANALYTICS} component={Analytics} />
                <PrivateRoute exact={true} path={Routes.EDUCATION} component={Education} />
                <PrivateRoute exact={true} path={Routes.REPORTS} component={Reports} />
                <PrivateRoute exact={true} path={`${Routes.REPORTS}/${RouteParams.REPORT_ID}`} component={Report} />
                <PrivateRoute exact={true} path={Routes.TRACKER} component={Tracker} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ADD_ACCOUNTS}/${RouteParams.NETWORK}/${RouteParams.CLIENT_ID}`}
                  component={AddAccounts}
                />
                <PrivateRoute exact={true} path={Routes.HISTORY} component={History} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.BUDGET}/${RouteParams.ACCOUNT_ID}/${RouteParams.CLIENT_ID}`}
                  component={Budget}
                />
                <PrivateRoute exact={true} path={`${Routes.SETTINGS}/${RouteParams.BLOCK_ID}`} component={Settings} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.OPTIMIZER}/${RouteParams.ACCOUNT_ID}`}
                  component={Optimizer}
                />
                {/* <PrivateRoute exact={true} path={Routes.FEEDMANAGER} component={FeedManager} />
                <PrivateRoute exact={true} path={Routes.FEEDMANAGER_ABOUT} component={FeedManagerAbout} />
                <PrivateRoute exact={true} path={Routes.FEEDMANAGER_WELCOME} component={FeedManagerWelcome} />
                <PrivateRoute exact={true} path={Routes.FEEDMANAGER_CREATE} component={FeedManagerCreate} /> */}
                <PrivateRoute exact={true} path={Routes.SHARE_AUDIENCES} component={ShareAudiences} />
                <PrivateRoute exact={true} path={Routes.AUDIENCES} component={Audiences} />
                <PrivateRoute exact={true} path={Routes.AUDIENCES_WELCOME} component={AudiencesWelcome} />
                <PrivateRoute exact={true} path={Routes.AUDIENCES_QUIZ} component={AudiencesQuiz} />
                <PrivateRoute exact={true} path={Routes.NOTIFICATIONS} component={Notifications} />
                <PrivateRoute exact={true} path={Routes.REFERRAL} component={Referral} />
                <PrivateRoute exact={true} path={Routes.MY_CASHBACK} component={MyCashback} />
                <PrivateRoute exact={true} path={`${Routes.ANTIBAN}`} component={AntiBan} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ANTIBAN}/${RouteParams.ANTIBAN_BLOCKING_SECTION}/${RouteParams.ANTIBAN_BLOCKING_SUBSECTION}`}
                  component={AntiBanBlockingActions}
                />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ANTIBAN}/${RouteParams.ANTIBAN_PROTECTION_SECTION}/${RouteParams.ANTIBAN_PROTECTION_SUBSECTION}`}
                  component={AntiBanIncreaseProtection}
                />
                <PrivateRoute exact={true} path={Routes.ANTIBAN_WELCOME} component={AntiBanWelcome} />
                <PrivateRoute exact={true} path={Routes.FREE_SERVICES} component={FreeServices} />
                <PrivateRoute exact={true} path={Routes.CAMPAIGN_EDITOR} component={CampaignEditor} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.CAMPAIGN_EDITOR}/${RouteParams.ACCOUNT_ID}/${RouteParams.FILTER_TAB}`}
                  component={CampaignReport}
                />
                <PrivateRoute exact={true} path={Routes.IMPORT_VK} component={ImportVK} />
                <PrivateRoute exact={true} path={Routes.IMPORT_VK_VERIFY} component={ImportVKVerify} />
                <PrivateRoute exact={true} path={Routes.ADD_AVITO} component={AddAvito} />
                <PrivateRoute exact={true} path={Routes.ADD_TELEGRAM} component={AddTelegram} />
                <PrivateRoute exact={true} path={Routes.ADD_YANDEX_PROMO_PAGE} component={AddYandexPromo} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ADD_VK_ADS}/${RouteParams.CLIENT_ID}`}
                  component={AddVKAds}
                />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ADD_VK_ADS_MARKET}/${RouteParams.CLIENT_ID}`}
                  component={AddVKAdsMarket}
                />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ADD_MY_TARGET}/${RouteParams.CLIENT_ID}`}
                  component={AddMyTarget}
                />
                <PrivateRoute exact={true} path={`${Routes.ADD_VK}/${RouteParams.CLIENT_ID}`} component={AddVK} />
                <PrivateRoute exact={true} path={Routes.ADD_YANDEX} component={AddYandex} />
                <PrivateRoute exact={true} path={Routes.ACCESS_DENIED} component={AccessDenied} />
                <Route exact={true} path={`${Routes.SIGNUP}`} component={Signup} />
                <Route path={`${Routes.SIGNUP}/${RouteParams.ID}`} component={Signup} />
                <Route exact={true} path={Routes.SIGNIN} component={Signin} />
                <Route exact={true} path={Routes.LOGOUT} component={Logout} />
                <Route exact={true} path={Routes.FORGOT_PASSWORD} component={ForgotPasswordRequest} />
                <Route exact={true} path={Routes.FORGOT_PASSWORD_CONFIRM} component={ForgotPasswordConfirm} />
                <Route
                  exact={true}
                  path={`${Routes.CONFIRM_CLIENT}/${RouteParams.CLIENT_UUID}`}
                  component={ConfirmClient}
                />
                <Route component={ErrorPage} />
              </Switch>
            </ContentBlock>
          </ContentWrapper>
          <AppModals />
        </>
      </ConnectedRouter>
    )
  }
}

export const mapStateToProps = (state: IApplicationState) => ({
  hasProfile: state.user.has_profile,
  isAuthorized: state.user.isAuthorized,
  router: state.router,
  isViewAdsMarking: shouldRenderAdsMarking(state),
})

export const Content = connect(mapStateToProps)(ContentClass)

export default Content
