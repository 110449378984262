export const NON_COMPANY_TERMS_URL = 'https://files.onespot.one/smb/referral-program_101.pdf'

export const COMPANY_TERMS_URL = 'https://files.onespot.one/smb/referral-program_101_company.pdf'

export enum MIXPANEL_EVENTS_REFERRAL {
  REFERRAL_PROGRAM_VISIT = 'ReferralProgramVisit',
  REFERRAL_PROGRAM_ITEM_COPY = 'ReferralProgramReferralItemCopy',
  REFERRAL_PROGRAM_INVITE_FRIENDS = 'ReferralProgramInviteFriends',
  REFERRAL_PROGRAM_TERMS = 'ReferralProgramTerms',
  REFERRAL_PROGRAM_FAQ = 'ReferralProgramFAQ',
  REFERRAL_PROGRAM_FILTER = 'ReferralProgramDateFilterClick',
  REFERRAL_PROGRAM_CASH_OUT = 'ReferralProgramCashOut',
  REFERRAL_PROGRAM_CASH_OUT_MISTAKE = 'ReferralProgramCashOutMistake',
  REFERRAL_PROGRAM_CASH_OUT_FIZ_REQ = 'ReferralProgramCashOutFizReq',
  REFERRAL_PROGRAM_CASH_OUT_FIZ_DONE = 'ReferralProgramCashOutFizDone',
  REFERRAL_PROGRAM_CASH_OUT_JUR_REQ = 'ReferralProgramCashOutJurReq',
  REFERRAL_PROGRAM_CASH_OUT_JUR_DONE = 'ReferralProgramCashOutJurDone',
  REFERRAL_PROGRAM_HISTORY = 'ReferralProgramOperationsHistory',
}

export const enum MIXPANEL_PARAMS_KEYS_REFERRAL {
  PLACE = 'place',
  ITEM = 'item',
  MENU_TYPE = 'menuType',
}

export const enum MIXPANEL_PARAMS_VALUES_REFERRAL {
  CODE = 'code',
  LINK = 'link',
  MENU = 'menu',
  LANDING = 'landing',
  CASH_RECEIVE = 'cashReceive',
  CASH_OUT = 'cashOut',
}
