import { AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { ICreateSubscriptionData, SUBSCRIPTION_SLUG } from './types'

import { API_URL } from '@/const'
import { customAxios } from '@/utils'

export enum MarketplaceActionTypes {
  MARKETPLACE_SET_ACCESS_GUIDELINES = 'MARKETPLACE_SET_ACCESS_GUIDELINES',
  MARKETPLACE_SET_ACCESS_GUIDELINES_LOADING = 'MARKETPLACE_SET_ACCESS_GUIDELINES_LOADING',
  MARKETPLACE_SET_ACCESS_GUIDELINES_REQUEST_ERROR = 'MARKETPLACE_SET_ACCESS_GUIDELINES_REQUEST_ERROR',
  MARKETPLACE_REMOVE_ACCESS_GUIDELINES_REQUEST_ERROR = 'MARKETPLACE_REMOVE_ACCESS_GUIDELINES_REQUEST_ERROR',

  MARKETPLACE_SET_PROGRESSBAR = 'MARKETPLACE_SET_PROGRESSBAR',
  MARKETPLACE_SET_PROGRESSBAR_LOADING = 'MARKETPLACE_SET_PROGRESSBAR_LOADING',
  MARKETPLACE_SET_PROGRESSBAR_REQUEST_ERROR = 'MARKETPLACE_SET_PROGRESSBAR_REQUEST_ERROR',
  MARKETPLACE_REMOVE_PROGRESSBAR_REQUEST_ERROR = 'MARKETPLACE_REMOVE_PROGRESSBAR_REQUEST_ERROR',

  MARKETPLACE_SET_SUBSCRIPTIONS = 'MARKETPLACE_SET_SUBSCRIPTIONS',
  MARKETPLACE_SET_SUBSCRIPTIONS_LOADING = 'MARKETPLACE_SET_SUBSCRIPTIONS_LOADING',
  MARKETPLACE_SET_SUBSCRIPTIONS_REQUEST_ERROR = 'MARKETPLACE_SET_SUBSCRIPTIONS_REQUEST_ERROR',
  MARKETPLACE_REMOVE_SUBSCRIPTIONS_REQUEST_ERROR = 'MARKETPLACE_REMOVE_SUBSCRIPTIONS_REQUEST_ERROR',

  MARKETPLACE_SET_SUBSCRIPTION_LOADING = 'MARKETPLACE_SET_SUBSCRIPTION_LOADING',
  MARKETPLACE_SET_SUBSCRIPTION_REQUEST_ERROR = 'MARKETPLACE_SET_SUBSCRIPTION_REQUEST_ERROR',

  MARKETPLACE_GET_SUBSCRIPTION_LOADING = 'MARKETPLACE_GET_SUBSCRIPTION_LOADING',
  MARKETPLACE_GET_SUBSCRIPTION_REQUEST_ERROR = 'MARKETPLACE_GET_SUBSCRIPTION_REQUEST_ERROR',

  MARKETPLACE_REMOVE_SUBSCRIPTION_REQUEST_ERROR = 'MARKETPLACE_REMOVE_SUBSCRIPTION_REQUEST_ERROR',

  MARKETPLACE_SET_LANDING_SHOW = 'MARKETPLACE_SET_LANDING_SHOW',
}

export function setMarketplaceGuidelines(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES, data }
}

export function setMarketplaceGuidelinesLoading(isLoading: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES_LOADING, isLoading }
}

export function setMarketplaceGuidelinesRequestError(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES_REQUEST_ERROR, data }
}

export function removeMarketplaceGuidelinesRequestError(): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_REMOVE_ACCESS_GUIDELINES_REQUEST_ERROR }
}

export function setMarketplaceProgressbar(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR, data }
}

export function setMarketplaceProgressbarLoading(isLoading: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR_LOADING, isLoading }
}

export function setMarketplaceProgressbarRequestError(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR_REQUEST_ERROR, data }
}

export function removeMarketplaceProgressbarRequestError(): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_REMOVE_PROGRESSBAR_REQUEST_ERROR }
}

export function setMarketplaceSubscriptions(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS, data }
}

export function setMarketplaceSubscriptionsLoading(isLoading: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS_LOADING, isLoading }
}

export function setMarketplaceSubscriptionsRequestError(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS_REQUEST_ERROR, data }
}

export function removeMarketplaceSubscriptionsRequestError(): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_REMOVE_SUBSCRIPTIONS_REQUEST_ERROR }
}

export function setMarketplaceLandingShow(isShowLanding: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_LANDING_SHOW, isShowLanding }
}

export function setMarketplaceSubscriptionLoading(isLoading: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTION_LOADING, isLoading }
}

export function setMarketplaceSubscriptionRequestError(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTION_REQUEST_ERROR, data }
}

export function getMarketplaceSubscriptionLoading(isLoading: boolean) {
  return { type: MarketplaceActionTypes.MARKETPLACE_GET_SUBSCRIPTION_LOADING, isLoading }
}

export function getMarketplaceSubscriptionRequestError(data: any): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_GET_SUBSCRIPTION_REQUEST_ERROR, data }
}

export function removeMarketplaceSubscriptionRequestError(): AnyAction {
  return { type: MarketplaceActionTypes.MARKETPLACE_REMOVE_SUBSCRIPTION_REQUEST_ERROR }
}

export const getAccessGuidelines = () => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/subscriptions_marketplace/access_guidelines/`
    try {
      dispatch(removeMarketplaceGuidelinesRequestError())
      dispatch(setMarketplaceGuidelinesLoading(true))
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      dispatch(setMarketplaceGuidelines(response.data))
      return response
    } catch (error) {
      dispatch(setMarketplaceGuidelinesRequestError(error))
      return Promise.reject(error)
    } finally {
      dispatch(setMarketplaceGuidelinesLoading(false))
    }
  }
}

export const getProgressbar = () => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/subscriptions_marketplace/progressbar/`
    try {
      dispatch(removeMarketplaceProgressbarRequestError())
      dispatch(setMarketplaceProgressbarLoading(true))
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      dispatch(setMarketplaceProgressbar(response.data))
      return response
    } catch (error) {
      dispatch(setMarketplaceProgressbarRequestError(error))
      return Promise.reject(error)
    } finally {
      dispatch(setMarketplaceProgressbarLoading(false))
    }
  }
}

export const getSubscriptions = (isLoadingShown = true) => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/subscriptions_marketplace/subscriptions/`
    try {
      dispatch(removeMarketplaceSubscriptionsRequestError())
      if (isLoadingShown) {
        dispatch(setMarketplaceSubscriptionsLoading(true))
      }
      const response: AxiosResponse<any> = await customAxios(url, 'GET', null, true)
      dispatch(setMarketplaceSubscriptions(response.data))
      return response
    } catch (error) {
      dispatch(setMarketplaceSubscriptionsRequestError(error))
      return Promise.reject(error)
    } finally {
      if (isLoadingShown) {
        dispatch(setMarketplaceSubscriptionsLoading(false))
      }
    }
  }
}

export const createSubscription = (data: ICreateSubscriptionData) => {
  const { data: slug, username } = data

  const requestData = username ? { data: { user_input: data.username } } : {}

  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    try {
      dispatch(removeMarketplaceSubscriptionRequestError())
      dispatch(setMarketplaceSubscriptionLoading(true))
      const url = `${API_URL}/subscriptions_marketplace/subscriptions/${slug}/`
      const response = await customAxios(url, 'POST', requestData, true)
      return response
    } catch (error) {
      dispatch(setMarketplaceSubscriptionRequestError(error))
      return Promise.reject(error)
    } finally {
      dispatch(setMarketplaceSubscriptionLoading(false))
    }
  }
}

export const getSubscription = (slug: SUBSCRIPTION_SLUG) => {
  return async (dispatch: Dispatch<AnyAction>): Promise<AxiosResponse<any>> => {
    try {
      dispatch(removeMarketplaceSubscriptionRequestError())
      dispatch(getMarketplaceSubscriptionLoading(true))
      const url = `${API_URL}/subscriptions_marketplace/subscriptions/${slug}/`
      const response = await customAxios(url, 'GET', true)
      return response
    } catch (error) {
      dispatch(getMarketplaceSubscriptionRequestError(error))
      return Promise.reject(error)
    } finally {
      dispatch(getMarketplaceSubscriptionLoading(false))
    }
  }
}
