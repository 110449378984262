import { Reducer } from 'redux'

import { MarketplaceActionTypes } from './actions'
import { reducerName } from './modals/actions'
import { IMarketplaceState } from './types'

const initialSubscription = {
  data: null,
  isLoading: false,
  requestError: {
    error: null,
    isError: false,
  },
}

const initialState: IMarketplaceState = {
  landing: {
    isShowLanding: false,
  },
  access_guidelines: {
    refill_balance: false,
    create_account: false,
    refill_account: false,
    isLoading: false,
    requestError: {
      error: null,
      isError: false,
    },
  },
  progressbar: {
    turnovers: [],
    current_turnover: null,
    isLoading: false,
    requestError: {
      error: null,
      isError: false,
    },
  },
  subscriptions: {
    list: [],
    isLoading: false,
    requestError: {
      error: null,
      isError: false,
    },
  },
  subscription: initialSubscription,
  modals: null,
}

const marketplaceReducer: Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES: {
      const results = action.data

      return {
        ...state,
        access_guidelines: {
          ...state.access_guidelines,
          ...results,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES_LOADING: {
      const isLoading = action.isLoading

      return {
        ...state,
        access_guidelines: {
          ...state.access_guidelines,
          isLoading,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_ACCESS_GUIDELINES_REQUEST_ERROR: {
      const error = action.data

      return {
        ...state,
        access_guidelines: {
          ...state.access_guidelines,
          requestError: {
            isError: Boolean(error),
            error,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_REMOVE_ACCESS_GUIDELINES_REQUEST_ERROR: {
      return {
        ...state,
        access_guidelines: {
          ...state.access_guidelines,
          requestError: {
            isError: false,
            error: null,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR: {
      const results = action.data

      return {
        ...state,
        progressbar: {
          ...state.progressbar,
          ...results,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR_LOADING: {
      const isLoading = action.isLoading
      return {
        ...state,
        progressbar: {
          ...state.progressbar,
          isLoading,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_PROGRESSBAR_REQUEST_ERROR: {
      const error = action.data
      return {
        ...state,
        progressbar: {
          ...state.progressbar,
          requestError: {
            isError: Boolean(error),
            error,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_REMOVE_PROGRESSBAR_REQUEST_ERROR: {
      return {
        ...state,
        progressbar: {
          ...state.progressbar,
          requestError: {
            isError: false,
            error: null,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS: {
      const results = action.data

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          list: results,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS_LOADING: {
      const isLoading = action.isLoading

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTIONS_REQUEST_ERROR: {
      const error = action.data

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          requestError: {
            isError: Boolean(error),
            error,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_REMOVE_SUBSCRIPTIONS_REQUEST_ERROR: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          requestError: {
            isError: false,
            error: null,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTION_LOADING:
    case MarketplaceActionTypes.MARKETPLACE_GET_SUBSCRIPTION_LOADING: {
      const isLoading = action.isLoading
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isLoading,
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_SUBSCRIPTION_REQUEST_ERROR:
    case MarketplaceActionTypes.MARKETPLACE_GET_SUBSCRIPTION_REQUEST_ERROR: {
      const error = action.data
      return {
        ...state,
        subscription: {
          ...state.subscription,
          requestError: {
            isError: Boolean(error),
            error,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_REMOVE_SUBSCRIPTION_REQUEST_ERROR: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          requestError: {
            isError: false,
            error: null,
          },
        },
      }
    }

    case MarketplaceActionTypes.MARKETPLACE_SET_LANDING_SHOW: {
      const isShowLanding = action.isShowLanding
      return {
        ...state,
        landing: {
          ...state.landing,
          isShowLanding,
        },
      }
    }

    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }

    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }

    default: {
      return state
    }
  }
}

export default marketplaceReducer
