import { Reducer } from 'redux'

import { AdPlatformsActionTypes, IAdPlatformsState } from './types'

const initialList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
}

const initialState: IAdPlatformsState = {
  adPlatformsList: initialList,
  isLoading: false,
  requestError: {
    error: null,
    isError: false,
  },
}

const reducer: Reducer<IAdPlatformsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AdPlatformsActionTypes.SET_AD_PLATFORMS: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        adPlatformsList: {
          ...listParams,
          results,
        },
      }
    }

    case AdPlatformsActionTypes.SET_REQUEST_ERROR: {
      const error = action.data

      return {
        ...state,
        requestError: {
          isError: Boolean(error),
          error,
        },
      }
    }

    case AdPlatformsActionTypes.REMOVE_REQUEST_ERROR: {
      return {
        ...state,
        requestError: {
          isError: false,
          error: null,
        },
      }
    }

    case AdPlatformsActionTypes.SET_IS_LOADING: {
      const { isLoading } = action

      return { ...state, isLoading }
    }

    default: {
      return state
    }
  }
}

export { reducer as adPlatformsReducer }
