import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    button: {
      whiteSpace: 'nowrap',
    },
    mobileButton: {
      padding: '7px 8px',
      minHeight: 35,
      width: '100%',
    },
  },
  { name: 'ReferralButton' }
)
